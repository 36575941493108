import { MutationFunction, useMutation as useQueryMutation, UseMutationOptions } from "react-query";
import { BackendError } from "api/connector";

export const useMutation = <
  TData extends unknown,
  TVariables extends unknown,
  TContext extends unknown,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options: UseMutationOptions<TData, BackendError, TVariables, TContext>,
) => {
  return useQueryMutation<TData, BackendError, TVariables, TContext>(mutationFn, options);
};
