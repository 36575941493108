import React from "react";
import styles from "./Toastr.module.css";
import cx from "classnames";
import neutralImg from "../../../assets/images/72.svg";
import succeedImg from "../../../assets/images/70.svg";
import warningImg from "../../../assets/images/71.svg";
import failureImg from "../../../assets/images/69.svg";
import { ToastSubscription } from "./ToastrController";

interface Props {
  type: ToastSubscription["type"];
  text?: string | React.ReactElement;
  title: string;
  clearHideTimeout?: () => void;
  lazyUnsubscribe?: () => void;
  className?: string;
}

export const Toastr = ({
  type,
  text,
  title,
  lazyUnsubscribe,
  clearHideTimeout,
  className = "",
}: Props) => {
  const variant = {
    success: { style: styles.succeed, img: succeedImg },
    neutral: { style: "", img: neutralImg },
    failure: { style: styles.failure, img: failureImg },
    warning: { style: styles.warning, img: warningImg },
  }[type];
  return (
    <div
      className={cx(styles.snackbar, styles.toast, styles.snackbarOpened, variant.style, className)}
      onMouseEnter={clearHideTimeout}
      onMouseLeave={lazyUnsubscribe}
    >
      <div className={styles.eventIcon}>
        <img src={variant.img} alt="" />
      </div>
      <span className={styles.snackbarContent}>
        <strong className={styles.title}>{title}</strong>
        <span>{text}</span>
      </span>
    </div>
  );
};
