import { BackendErrorDict } from "api/connector";
import { useRef } from "react";
import { QueryObserverOptions, useQuery, UseQueryResult } from "react-query";
import { Assign } from "utility-types";

export type ErrorType = { [key: string]: any; _httpStatus_: number };

export const createApiQuery =
  <TRes extends unknown, T>(func: (args: T) => Promise<TRes>) =>
  (
    key: string[],
    args: T,
    options?: QueryObserverOptions<TRes, BackendErrorDict>,
  ): Assign<
    UseQueryResult,
    {
      data: TRes | null;
      error: ErrorType | null;
    }
  > => {
    const error = useRef<ErrorType | null>(null);
    const res = useQuery<any, any>(key, () => func(args), {
      onError: (err) => {
        if (err.response.headers["content-type"] === "application/json") {
          error.current = err;
        } else {
          error.current = {} as any;
        }

        Object.defineProperty(error.current, "_httpStatus_", {
          value: err.response.status,
          enumerable: false,
        });
      },
      ...options,
    });

    if (res.isError) {
      return {
        ...res,
        error: error.current,
        data: null,
      };
    } else {
      return {
        ...res,
        error: null,
        data: res.data,
      };
    }
  };
