import sygnet from "assets/images/sygnetNB.png";
import { Field } from "components/common/form/Field";
import { Formik, FormikHelpers } from "formik";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { obtainToken } from "api/users/calls";
import { useAuthStore } from "stores/auth";
import { FieldError } from "components/common/form/FieldError";
import { Text } from "components/common/text/Text";
import { FieldCheckbox } from "components/common/form/FormCheckbox";
import cuid from "cuid";

type Values = {
  email: string;
  password: string;
  rememberMe: boolean;
};

const validationSchema: yup.SchemaOf<Values> = yup.object({
  email: yup.string().email("Wpisz poprawny adres e-mail").required("Wpisz adres e-mail"),
  password: yup.string().min(8, "Hasło powinno mieć co najmniej 8 znaków").required("Wpisz hasło"),
  rememberMe: yup.boolean().default(false),
});

export const Login = () => {
  const setAuthenticated = useAuthStore((store) => store.actions.setAuthenticated);
  const history = useHistory();

  async function submit(values: Values, helpers: FormikHelpers<Values>) {
    try {
      const payload = await obtainToken(values);
      localStorage.setItem("token", payload.access);
      localStorage.setItem("refreshToken", payload.refresh);
      setAuthenticated();
      history.push("/");
    } catch (error) {
      //@ts-ignore
      helpers.setErrors({ detail: "Podane dane nie pozwalają na uwierzytelnienie" });
    }
  }
  const initialValues: Values = { email: "", password: "", rememberMe: false };

  const autocomplete = cuid();
  return (
    <Formik initialValues={initialValues} onSubmit={submit} validationSchema={validationSchema}>
      {({ isSubmitting, handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete={autocomplete}>
            <div className="d-flex align-items-center justify-content-center h-100">
              <div className="w-50">
                <div className="d-flex align-items-start pb-5">
                  <img className="sygnetSmall" src={sygnet} alt="" />
                  <Text fontSize="1.65rem">Panel pacjenta</Text>
                </div>
                <div className="row d-flex">
                  <div className="col-12 col-md-6 pe-4">
                    <div className="pb-2">
                      <Field<Values> name="email" placeholder="e-mail" inputClassName="input" />
                      <Field<Values>
                        name="password"
                        placeholder="hasło"
                        type="password"
                        inputClassName="input"
                      />
                      <FieldCheckbox name="rememberMe" text="Zapamiętaj mnie" />
                      <FieldError name="detail" />
                    </div>
                    <div>
                      <button className="btnPrimary px-2" type="submit" disabled={isSubmitting}>
                        Zaloguj się{isSubmitting && "..."}
                      </button>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <h5 className="mt-2">Nie masz jeszcze loginu?</h5>

                    <div className="mt-3">
                      <Link to="/register" className="btnSecondary px-2" type="submit">
                        Zarejestruj się
                      </Link>
                    </div>
                  </div>
                </div>
                <Link to="/password/reset" className="link mt-3" type="submit">
                  Zapomniałeś hasła?
                </Link>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
