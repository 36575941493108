import vegetablesImg from "../../assets/images/fruits and veggies.svg";
import dietImg from "../../assets/images/diet.svg";
import alcoholImg from "../../assets/images/alcohol.svg";
import junkFoodImg from "../../assets/images/avoid artificial.svg";
import freshAirImg from "../../assets/images/outdoors.svg";
import wholeGrainImg from "../../assets/images/whole grain.svg";
import antibioticsImg from "../../assets/images/avoid antibiotics.svg";
import coffeeImg from "../../assets/images/coffee.svg";
import cigaretsImg from "../../assets/images/smoking.svg";
import yougurtImg from "../../assets/images/yougurt.svg";
import vitDImg from "../../assets/images/vitaminD.svg";
import excerciseImg from "../../assets/images/physical activity.svg";
import relaxImg from "../../assets/images/relax.svg";
import happinessImg from "../../assets/images/happiness.svg";
import weightImg from "../../assets/images/weight.svg";
import antiInflammatoryImg from "../../assets/images/anti-inflammatory.svg";
import probioticImg from "../../assets/images/probiotic.svg";
import bonesImg from "../../assets/images/bones.svg";
import heartImg from "../../assets/images/heart.svg";
import bloodImg from "../../assets/images/blood.svg";
import metabolismImg from "../../assets/images/metabolism.svg";
import stressImg from "../../assets/images/nerves.svg";
import fertilityImg from "../../assets/images/reproduction.svg";
import antiPathogenicImg from "../../assets/images/anti-pathogenic.svg";
import diarrheaImg from "../../assets/images/diarrhea.svg";
import deathImg from "../../assets/images/death.svg";
import feverImg from "../../assets/images/fever.svg";
import inflammationImg from "../../assets/images/inflammation.svg";
import energyImg from "../../assets/images/energy.svg";
import brainImg from "../../assets/images/brain.svg";
import gabaImg from "../../assets/images/GABA.svg";
import serotoninImg from "../../assets/images/serotonin.svg";
import vitKImg from "../../assets/images/vitaminK.svg";
import vitBImg from "../../assets/images/vitaminB.svg";
import oportunisticImg from "../../assets/images/pathogenic.svg";
import gutProtectImg from "../../assets/images/mucosa.svg";
import obesityImg from "../../assets/images/obesity.svg";
import butterAcidImg from "../../assets/images/butyric.svg";
import vinegarAcidImg from "../../assets/images/probiotic.svg";
import propionicAcidImg from "../../assets/images/propionic.svg";
// Main icons for bacteria section
import gabaWave from "../../assets/images/version1/GABAWave.svg";
import serotonineWave from "../../assets/images/version1/serotoninWave.svg";
import vitamine_kWave from "../../assets/images/version1/vitaminBWave.svg";
import vitamine_bWave from "../../assets/images/version1/vitaminKWave.svg";
import probioticWave from "../../assets/images/version1/probioticWave.svg";
import oportunisticWave from "../../assets/images/version1/pathogenicWave.svg";
import gut_protectWave from "../../assets/images/version1/mucosaWave.svg";
import obesityWave from "../../assets/images/version1/obesityWave.svg";
import butter_acidWave from "../../assets/images/version1/butyricWave.svg";
import vinegar_acidWave from "../../assets/images/version1/aceticWave.svg";
import propionic_acidWave from "../../assets/images/version1/propionicWave.svg";
import poliamin_synthesisWave from "../../assets/images/version1/polyaminesWave.svg";

import poliaminSynthesisImg from "../../assets/images/polyamines.svg";
import immunesystemImg from "../../assets/images/immunesystem.svg";


import acetic2 from "../../assets/images/version2/acetic2.svg";
import beneficial2 from "../../assets/images/version2/beneficial_bacteria2.svg";
import butyric2 from "../../assets/images/version2/butyric2.svg";
import GABA2 from "../../assets/images/version2/GABA2.svg";
import immunostimulating2 from "../../assets/images/version2/immunostimulating2.svg";
import lactic2 from "../../assets/images/version2/lactic2.svg";
import mucosa2 from "../../assets/images/version2/mucosa2.svg";
import opportunistic2 from "../../assets/images/version2/opportunistic2.svg";
import pathogenic2 from "../../assets/images/version2/pathogenic2.svg";
import polyamines2 from "../../assets/images/version2/polyamines2.svg";
import propionic2 from "../../assets/images/version2/propionic2.svg";
import serotonin2 from "../../assets/images/version2/serotonin2.svg";
import sulfur2 from "../../assets/images/version2/sulfur2.svg";
import trimethylamine2 from "../../assets/images/version2/trimethylamine2.svg";
import vitaminB_2 from "../../assets/images/version2/vitaminB_2.svg";
import vitaminK_2 from "../../assets/images/version2/vitaminK_2.svg";

import acetic3 from "../../assets/images/version3/acetic2.svg";
import beneficial3 from "../../assets/images/version3/beneficial_bacteria2.svg";
import butyric3 from "../../assets/images/version3/butyric2.svg";
import GABA3 from "../../assets/images/version3/GABA2.svg";
import immunostimulating3 from "../../assets/images/version3/immunostimulating2.svg";
import lactic3 from "../../assets/images/version3/lactic2.svg";
import mucosa3 from "../../assets/images/version3/mucosa2.svg";
import opportunistic3 from "../../assets/images/version3/opportunistic2.svg";
import pathogenic3 from "../../assets/images/version3/pathogenic2.svg";
import polyamines3 from "../../assets/images/version3/polyamines2.svg";
import propionic3 from "../../assets/images/version3/propionic2.svg";
import serotonin3 from "../../assets/images/version3/serotonin2.svg";
import sulfur3 from "../../assets/images/version3/sulfur2.svg";
import trimethylamine3 from "../../assets/images/version3/trimethylamine2.svg";
import vitaminB_3 from "../../assets/images/version3/vitaminB_2.svg";
import vitaminK_3 from "../../assets/images/version3/vitaminK_2.svg";

import Akkermansia_muciniphila from '../../assets/images/diag/Akkermansia muciniphila_density.png';
import Anaerostipes from '../../assets/images/diag/Anaerostipes_density.png';
import Anaerotruncus_colihominis from '../../assets/images/diag/Anaerotruncus colihominis_density.png';
import Bacteroides from '../../assets/images/diag/Bacteroides_density.png';
import Bacteroides_fragilis from '../../assets/images/diag/Bacteroides fragilis_density.png';
import Barnesiella from '../../assets/images/diag/Barnesiella_density.png';
import Bifidobacterium from '../../assets/images/diag/Bifidobacterium_density.png';
import Bilophila_wadsworthia from '../../assets/images/diag/Bilophila wadsworthia_density.png';
import Clostridium from '../../assets/images/diag/Clostridium spp_density.png';
import Coprococcus_eutactus from '../../assets/images/diag/Coprococcus eutactus_density.png';
import Desulfovibrio from '../../assets/images/diag/Desulfovibrio spp_density.png';
import Enterobacteriaceae from '../../assets/images/diag/Enterobacteriaceae_density.png';
import Escherichia_coli from '../../assets/images/diag/Escherichia coli_density.png';
import Faecalibacterium_prausnitzii from '../../assets/images/diag/Faecalibacterium prausnitzii_density.png';
import Fusobacterium_nucleatum from '../../assets/images/diag/Fusobacterium nucleatum_density.png';
import Lachnospira from '../../assets/images/diag/Lachnospira_density.png';
import Lactobacillus from '../../assets/images/diag/Lactobacillus_density.png';
import Lactobacillaceae from '../../assets/images/diag/Lactobacillaceae_density.png';
import Limosilactobacillus from '../../assets/images/diag/Limosilactobacillus_density.png';
import Megamonas from '../../assets/images/diag/Megamonas_density.png';
import Odoribacter from '../../assets/images/diag/Odoribacter_density.png';
import Phocaeicola_vulgatus from '../../assets/images/diag/Phocaeicola vulgatus_density.png';
import Roseburia from '../../assets/images/diag/Roseburia_density.png';
import Ruminococcus from '../../assets/images/diag/Ruminococcus_density.png';

import aceticLine from "../../assets/images/diag/acetic2_density.png";
import beneficialLine from "../../assets/images/diag/beneficial_bacteria2_density.png";
import butyricLine from "../../assets/images/diag/butyric2_density.png";
import GABALine from "../../assets/images/diag/GABA2_density.png";
import immunostimulatingLine from "../../assets/images/diag/immunostimulating2_density.png";
import lacticLine from "../../assets/images/diag/lactic2_density.png";
import mucosaLine from "../../assets/images/diag/mucosa2_density.png";
import opportunisticLine from "../../assets/images/diag/opportunistic2_density.png";
import pathogenicLine from "../../assets/images/diag/pathogenic2_density.png";
import polyaminesLine from "../../assets/images/diag/polyamines2_density.png";
import propionicLine from "../../assets/images/diag/propionic2_density.png";
import serotoninLine from "../../assets/images/diag/serotonin2_density.png";
import sulfurLine from "../../assets/images/diag/sulfur2_density.png";
import trimethylamineLine from "../../assets/images/diag/trimethylamine2_density.png";
import vitaminBLine from "../../assets/images/diag/vitaminB_2_density.png";
import vitaminKLine from "../../assets/images/diag/vitaminK_2_density.png";
import diversityLine from "../../assets/images/diag/Sdiversity_density.png";
import dominanceLine from "../../assets/images/diag/dominance5_density.png";
import nspeciesLine from "../../assets/images/diag/number_of_species_density.png";


export const page4IconsDict: Record<string, any> = {
  relax: { icon: relaxImg, label: "relaks" },
  happiness: { icon: happinessImg, label: "szczęście" },
  weight: { icon: weightImg, label: "waga" },
  bones: { icon: bonesImg, label: "kości" },
  heart: { icon: heartImg, label: "serce" },
  blood: { icon: bloodImg, label: "krew" },
  metabolism: { icon: metabolismImg, label: "metabolizm" },
  stress: { icon: stressImg, label: "stres" },
  fertility: { icon: fertilityImg, label: "płodność" },
  "anti-pathogenic": { icon: antiPathogenicImg, label: "działanie przeciwpatogenne" },
  "anti-inflammatory": { icon: antiInflammatoryImg, label: "działanie przeciwzapalne" },
  diarrhea: { icon: diarrheaImg, label: "biegunka" },
  death: { icon: deathImg, label: "śmierć" },
  fever: { icon: feverImg, label: "gorączka" },
  inflammation: { icon: inflammationImg, label: "stan zapalny" },
  energy: { icon: energyImg, label: "energia" },
  immunesystem: { icon: immunesystemImg, label: "układ odpornościowy" },
  brain: { icon: brainImg, label: "mózg" },
};

export const page4MainIcons: Record<
  string,
  {
    icon: string;
    diagram: string;
  }
> = {
  gaba_icon: { icon: gabaImg, diagram: gabaWave },
  serotonine: { icon: serotoninImg, diagram: serotonineWave },
  vitamine_k: { icon: vitKImg, diagram: vitamine_kWave },
  vitamine_b: { icon: vitBImg, diagram: vitamine_bWave },
  probiotic: { icon: probioticImg, diagram: probioticWave },
  oportunistic_icon: { icon: oportunisticImg, diagram: oportunisticWave },
  gut_protect_icon: { icon: gutProtectImg, diagram: gut_protectWave },
  obesity_icon: { icon: obesityImg, diagram: obesityWave },
  butter_acid_icon: { icon: butterAcidImg, diagram: butter_acidWave },
  vinegar_acid: { icon: vinegarAcidImg, diagram: vinegar_acidWave },
  propionic_acid: { icon: propionicAcidImg, diagram: propionic_acidWave },
  poliamin_synthesis: { icon: poliaminSynthesisImg, diagram: poliamin_synthesisWave },

  acetic2: { icon: vinegarAcidImg, diagram: acetic2 },
  beneficial2: { icon: propionicAcidImg, diagram: beneficial2 },
  butyric2: { icon: butterAcidImg, diagram: butyric2 },
  gaba2: { icon: gabaImg, diagram: GABA2 },
  immunostimulating2: { icon: obesityImg, diagram: immunostimulating2 },
  lactic2: { icon: gabaImg, diagram: lactic2 },
  mucosa2: { icon: gutProtectImg, diagram: mucosa2 },
  opportunistic2: { icon: obesityImg, diagram: opportunistic2 },
  pathogenic2: { icon: oportunisticImg, diagram: pathogenic2 },
  polyamines2: { icon: poliaminSynthesisImg, diagram: polyamines2 },
  propionic2: { icon: propionicAcidImg, diagram: propionic2 },
  serotonin2: { icon: serotoninImg, diagram: serotonin2 },
  sulfur2: { icon: vinegarAcidImg, diagram: sulfur2 },
  trimethylamine2: { icon: vitBImg, diagram: trimethylamine2 },
  vitaminB_2: { icon: vitBImg, diagram: vitaminB_2 },
  vitaminK_2: { icon: vitKImg, diagram: vitaminK_2 },

  acetic3: { icon: vinegarAcidImg, diagram: acetic3 },
  beneficial3: { icon: propionicAcidImg, diagram: beneficial3 },
  butyric3: { icon: butterAcidImg, diagram: butyric3 },
  gaba3: { icon: gabaImg, diagram: GABA3 },
  immunostimulating3: { icon: obesityImg, diagram: immunostimulating3 },
  lactic3: { icon: gabaImg, diagram: lactic3 },
  mucosa3: { icon: gutProtectImg, diagram: mucosa3 },
  opportunistic3: { icon: obesityImg, diagram: opportunistic3 },
  pathogenic3: { icon: oportunisticImg, diagram: pathogenic3 },
  polyamines3: { icon: poliaminSynthesisImg, diagram: polyamines3 },
  propionic3: { icon: propionicAcidImg, diagram: propionic3 },
  serotonin3: { icon: serotoninImg, diagram: serotonin3 },
  sulfur3: { icon: vinegarAcidImg, diagram: sulfur3 },
  trimethylamine3: { icon: vitBImg, diagram: trimethylamine3 },
  vitaminB_3: { icon: vitBImg, diagram: vitaminB_3 },
  vitaminK_3: { icon: vitKImg, diagram: vitaminK_3 },
};

export const recommendationIconDict: Record<string, any> = {
  diet: dietImg,
  etanol: alcoholImg,
  junk_food: junkFoodImg,
  fresh_air: freshAirImg,
  corps: wholeGrainImg,
  antibiotics: antibioticsImg,
  polyphenol: coffeeImg,
  cigarets: cigaretsImg,
  fermentation: yougurtImg,
  d_vit: vitDImg,
  vegetable: vegetablesImg,
  excercise: excerciseImg,
};



export const LineIconDict: Record<string, any> = {
  aceticLine: aceticLine,
  beneficialLine: beneficialLine,
  butyricLine: butyricLine,
  GABALine: GABALine,
  immunostimulatingLine: immunostimulatingLine,
  lacticLine: lacticLine ,
  mucosaLine: mucosaLine ,
  opportunisticLine: opportunisticLine ,
  pathogenicLine: pathogenicLine ,
  polyamineLine:  polyaminesLine ,
  polyaminesLine:  polyaminesLine ,
  propionicLine:  propionicLine ,
  serotoninLine:  serotoninLine ,
  sulfurLine: sulfurLine ,
  trimethylamineLine: trimethylamineLine ,
  vitaminBLine: vitaminBLine ,
  vitaminKLine: vitaminKLine ,
  diversityLine: diversityLine,
  dominanceLine: dominanceLine, 
  nspeciesLine: nspeciesLine, 
  "Akkermansia muciniphila": Akkermansia_muciniphila,
  "Anaerostipes": Anaerostipes,
  "Anaerotruncus colihominis": Anaerotruncus_colihominis,
  "Bacteroides": Bacteroides,
  "Bacteroides fragilis": Bacteroides_fragilis,
  "Barnesiella": Barnesiella,
  "Bifidobacterium": Bifidobacterium,
  "Bilophila wadsworthia": Bilophila_wadsworthia,
  "Clostridium spp": Clostridium,
  "Coprococcus eutactus": Coprococcus_eutactus,
  "Desulfovibrio spp": Desulfovibrio,
  "Enterobacteriaceae": Enterobacteriaceae,
  "Escherichia coli": Escherichia_coli,
  "Faecalibacterium prausnitzii": Faecalibacterium_prausnitzii,
  "Fusobacterium nucleatum": Fusobacterium_nucleatum ,
  "Lachnospira": Lachnospira,
  "Lactobacillus": Lactobacillus,
  "Lactobacillaceae": Lactobacillaceae,
  "Limosilactobacillus": Limosilactobacillus,
  "Megamonas": Megamonas,
  "Odoribacter": Odoribacter,
  "Phocaeicola vulgatus": Phocaeicola_vulgatus,
  "Roseburia": Roseburia,
  "Ruminococcus": Ruminococcus
};

export const indexToColorDict: Record<string, string> = {
  0: "#facf66",
  1: "#b1235c",
  2: "#79357e",
  3: "#e98434",
  4: "#d3342e",
};

const formatterPerc = new Intl.NumberFormat('pl-PL', {
  maximumFractionDigits: 2, 
  minimumFractionDigits: 2
});
const formatter = new Intl.NumberFormat('pl-PL', {
  maximumFractionDigits: 2
});

const formatterPerc3 = new Intl.NumberFormat('pl-PL', {
  maximumFractionDigits: 3, 
  minimumFractionDigits: 3
});

export const printNumber = (e: number, perc: boolean) => {
  if (perc) return(formatterPerc.format(e));
  return(formatter.format(e));
}

export const printNumberDiag = (e: number, perc: boolean) => {
  if (perc && e <= 0.005 && e >= 0.002 ) return(formatterPerc3.format(e));
  if (perc) return(formatterPerc.format(e));
  return(formatter.format(e));
}

const formatterPercDot = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2, 
  minimumFractionDigits: 2
});
const formatterDot = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2
});

export const printNumberReport = (e: number, perc: boolean) => {
  if (perc) return(formatterPercDot.format(e)+"%");
  return(formatterDot.format(e));
}