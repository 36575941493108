import { patchPollResults } from "api/poll/calls";
import { usePoll, usePollResults } from "api/poll/hooks";
import { pollKeys } from "api/poll/keys";
import { PollResults } from "api/poll/models";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import styles from "./Poll.module.css";
import { cx } from "utilities/cx";
import { dateFns } from "utilities/dateFns";
import { useToastr } from "components/utils/toastr/ToastrController";
import { getAnyErrorKey } from "utilities/getAnyErrorKey";
import { useMutation } from "hooks/useMutation";
import { useMemo } from "react";

Survey.SurveyModel.prototype.scrollToTopOnPageChange = function () {};

export const Poll = () => {
  const { id, uuid } = useParams<{ id: string; uuid: string }>();
  const queryClient = useQueryClient();
  const history = useHistory();
  const toastr = useToastr();

  const { data: pollResults, isLoading: inProgressPollResults } = usePollResults(
    pollKeys.pollResults(id),
    Number(id),
    {
      onError: (err) => {
        toastr.warning({
          title: "Oj, coś nie tak...",
          text: getAnyErrorKey(err),
        });
        history.goBack();
      },
    },
  );

  const { data: poll, isLoading: inProgressPoll } = usePoll(pollKeys.poll(uuid), uuid, {
    onError: (err) => {
      toastr.warning({
        title: "Oj, coś nie tak...",
        text: getAnyErrorKey(err),
      });
      history.goBack();
    },
  });

  const patchPollResultsMutation = useMutation(
    (args: Partial<PollResults>) => patchPollResults(args, Number(id)),
    {
      onSuccess: (payload) => {
        queryClient.setQueryData(pollKeys.pollResults(id), payload);
      },
      onError: (err) => {
        toastr.warning({
          title: "Oj, coś nie tak...",
          text: getAnyErrorKey(err),
        });
      },
    },
  );

  const schemaWithoutFirstPage = (() => {
    //@ts-ignore
    return { ...poll?.schema, firstPageIsStarted: false, pages: poll?.schema.pages.slice(1) };
  })();

  const model = useMemo(
    () =>
      new Survey.Model(
        pollResults?.data?.currentPageNo === undefined ? poll?.schema : schemaWithoutFirstPage,
      ),
    [poll],
  );

  if (inProgressPollResults || inProgressPoll) {
    return <div>Ładowanie ankiety</div>;
  }

  const onComplete = (survey: any) => {
    pollResults?.is_done || patchPollResultsMutation.mutate({
      data: survey.data,
      is_done: true,
      finished: dateFns.format(new Date(), "yyyy-MM-dd HH:mm"),
    });
  };

  const myCss = {
    root: cx("sv_main sv_frame", styles.root),
    container: cx("sv_container", styles.textWhite, styles.bgTransparent),
    header: cx("sv_header", styles.textWhite, styles.bgTransparent),
    body: cx("sv_body", styles.textWhite, styles.bgTransparent, "border-top-0"),
    title: cx(styles.textWhite, styles.bgTransparent),
    description: cx(styles.textWhite, styles.bgTransparent, styles.fullWidth),
    progress: "progress center-block mx-auto mb-4",
    progressBar: cx("progress-bar", styles.progressBar),
    progressTextUnderBar: "sv-hidden",
    progressButtonsContainerCenter: "sv_progress-buttons__container-center",
    progressButtonsContainer: "sv_progress-buttons__container",
    progressButtonsImageButtonLeft: "d-none",
    progressButtonsImageButtonRight: "d-none",
    progressButtonsImageButtonHidden: "d-none",
    progressButtonsListContainer: "",
    progressButtonsList: cx("d-flex align-items-start", styles.stepper),
    // progressButtonsListElementPassed: styles.stepActive,
    progressButtonsListElementCurrent: styles.stepActive,
    progressButtonsListElementNonClickable: styles.step,
    progressButtonsPageTitle: styles.stepNumber,
    progressButtonsPageDescription: styles.stepDescription,
    row: cx("sv_row", styles.bgTransparent, styles.textWhite, "border-bottom-0"),
    question: {
      mainRoot: cx("sv_q sv_qstn ", styles.input),
      title: "label",
      titleExpandable: "sv_q_title_expandable",
      hasError: styles.invalid,
    },
    panel: {
      title: styles.frameLabel,
      container: cx("sv_p_container", styles.frame),
    },
    error: {
      root: "",
      icon: "",
      item: styles.validationMessage,
      locationTop: "sv_qstn_error_top",
      locationBottom: "sv_qstn_error_bottom",
    },
    boolean: {
      switch: cx("sv-boolean__switch", styles.switch),
    },
    checkbox: {
      label: styles.option,
      materialDecorator: styles.checkmark,
    },
    radiogroup: {
      label: styles.option,
      materialDecorator: styles.radiomark,
    },
    ranking: {
      root: "sv-ranking",
      rootMobileMod: "sv-ranking--mobile",
      rootDragMod: "sv-ranking--drag",
      item: "sv-ranking-item",
      itemContent: cx("sv-ranking-item__content", styles.bgTransparent, styles.rankingContent),
      itemIndex: cx("sv-ranking-item__index", styles.rankingIndex),
      controlLabel: cx("sv-ranking-item__text", styles.rankingViewer),
      itemGhostNode: "sv-ranking-item__ghost",
      itemIconContainer: "sv-ranking-item__icon-container",
      itemIcon: cx(styles.rankingIcon, "sv-ranking-item__icon"),
      itemIconHoverMod: "sv-ranking-item__icon--hover",
      itemIconFocusMod: "sv-ranking-item__icon--focus",
      itemGhostMod: "sv-ranking-item--ghost",
      itemDragMod: cx("sv-ranking-item--drag", styles.textWhite),
    },
    dropdown: {
      root: styles.dropdownContainer,
      control: styles.dropdown,
      other: "sv_q_dd_other form-control",
    },
    matrix: {
      cellTextSelected: cx("sv_q_m_cell_selected", styles.matrixSelected),
      cell: cx("sv_q_m_cell", styles.matrixCell),
      label: cx("sv_q_m_label", styles.matrixOption),
    },
    file: {
      placeholderInput: "sv_q_file_placeholder",
      preview: "sv_q_file_preview",
      fileInput: styles.fileInput,
      removeFile: styles.removeFile,
      fileDecorator: "sv-hidden",
      removeButtonBottom: "sv-hidden",
    },
  };

  //@ts-ignore
  const pages = poll?.schema.pages[3].elements.slice(0, 2);

  const elements = pages.reduce((acc: Record<string, any>, el: any) => {
    el.elements.forEach((elem: any) => {
      acc[elem.name] = elem.rows;
    });

    return acc;
  }, {});

  return (
    <Survey.Survey
      model={model}
      data={pollResults?.data}
      css={myCss}
      onComplete={onComplete}
      currentPageNo={pollResults?.data?.currentPageNo || 0}
      onCurrentPageChanged={() => {
        window.scrollTo(0, 0);
      }}
      onAfterRenderPage={(survey: any) => {
        const test = document.querySelectorAll(".select-many");
        test.forEach((el) => {
          const id = el.getAttribute("id")!;
          el.className = "select-many select-many-button";

          const data = elements[id].reduce((acc: Record<string, any>, el: any) => {
            acc[el.value] = "false";
            return acc;
          }, {});

          pollResults?.is_done || el.addEventListener("click", function (event) {
            patchPollResultsMutation.mutate({
              data: { ...survey.data, [id]: data, currentPageNo: survey.currentPageNo },
            });
          });
        });
      }}
      onValueChanged={(survey: any, field: any) => {
        if (field.name.includes("select-many")) {
          const [, name] = field.name.split("/");

          const data = elements[name].reduce((acc: Record<string, any>, el: any) => {
            acc[el.value] = field.value.all;
            return acc;
          }, {});

          pollResults?.is_done || patchPollResultsMutation.mutate({
            data: { ...survey.data, [name]: data, currentPageNo: survey.currentPageNo },
          });
        } else {
          pollResults?.is_done || patchPollResultsMutation.mutate({
            data: { ...survey.data, currentPageNo: survey.currentPageNo },
          });
        }
      }}
    />
  );
};
