import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { activateUser } from "api/users/calls";
import { useAuthStore } from "stores/auth";
import { logout } from "App";
import { getAnyErrorKey } from "utilities/getAnyErrorKey";
import { BackendErrorDict } from "api/connector";

export const ActivateUser = ({ match }: RouteComponentProps<{ uid: string; token: string }>) => {
  const { token, uid } = match.params;
  const setAuthenticated = useAuthStore((store) => store.actions.setAuthenticated);
  const history = useHistory();
  const [error, setError] = useState("");

  useEffect(() => {
    async function activate() {
      try {
        const payload = await activateUser({ uid, token });
        localStorage.setItem("token", payload.access);
        localStorage.setItem("refreshToken", payload.refresh);
        setAuthenticated();
        history.push("/");
      } catch (err) {
        setError(getAnyErrorKey(err as BackendErrorDict) as any);
      }
    }
    activate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (error) {
    return <div>Wystąpił błąd: {error}</div>;
  }

  return <div onClick={logout}>Trwa aktywacja użytkownika...</div>;
};
