import createStore from "zustand";
import { User as UserInfo } from "../api/users/models";
import { immerMiddleware } from "./middlewares";
import { persist } from "zustand/middleware";
import { useHydrationStore } from "./hydration";
import localForage from "localforage";

type Me = UserInfo;

export type AuthStore = {
  user: Me | null;
  isAuthenticated: boolean;
  actions: {
    saveUser: (user: UserInfo) => void;
    clear: () => void;
    setAuthenticated: () => void;
  };
};

export const useAuthStore = createStore<AuthStore>(
  persist(
    immerMiddleware((set) => ({
      user: null,
      isAuthenticated: false,
      actions: {
        saveUser: (user) =>
          set((state) => {
            state.user = {
              ...user,
            };
          }),
        clear: () =>
          set((state) => {
            state.user = null;
            state.isAuthenticated = false;
          }),
        setAuthenticated: () =>
          set((state) => {
            state.isAuthenticated = true;
          }),
      },
    })),
    {
      name: "auth",
      getStorage: () => localForage as any,
      blacklist: ["actions"],
      onRehydrateStorage() {
        return (store) => {
          if (store) {
            useHydrationStore.setState((s) => ({ ...s, authRehydrated: true }));
          }
        };
      },
      // version: 0,
      // example of migration:
      // migrate:((state,version) => {
      //   if(version === 1) {
      //     return {...state, something: 1};
      //   }
      // })
    },
  ),
);

export function useMe() {
  const me = useAuthStore((store) => store.user);
  return me!;
}
