import React, { createContext, useState, useCallback, useMemo, useContext } from "react";
import { Modal } from "../../common/modal/Modal";

export const context = createContext<any>({});

export interface Options {
  callback: (...args: any) => void;
  text?: React.ReactNode;
  confirmText?: string;
}

type State = {
  isOpen: boolean;
  options: Options | null;
};

export const ConfirmModalController = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState<State>({
    isOpen: false,
    options: null,
  });
  const close = useCallback(() => setState((s) => ({ isOpen: false, options: s.options })), []);
  const open = useCallback((options: Options) => setState({ options, isOpen: true }), []);
  const value = useMemo(() => ({ ...state, close, open }), [state, open, close]);
  return (
    <context.Provider value={value}>
      {children}
      <Modal controller={{ isOpen: state.isOpen, close }} size="auto">
        {state.options ? (
          <div className="p-3 pe-5">
            {state.options.text || "Are you sure?"}
            <div className="d-flex pt-3">
              <button
                type="button"
                className="me-2 btn-primary"
                onClick={() => {
                  state.options!.callback();
                  close();
                }}
              >
                {state.options.confirmText || "Confirm"}
              </button>
              <button type="button" onClick={close} className="btn-secondary">
                Close
              </button>
            </div>
          </div>
        ) : (
          <div />
        )}
      </Modal>
    </context.Provider>
  );
};

export const useConfirmModal = () => {
  const modal = useContext(context);
  return {
    open: ({ text, callback, confirmText }: Options) => {
      modal.open({ callback, text, confirmText });
    },
    close: modal.close,
  };
};
