import axios, { AxiosError } from "axios";
import jwtDecode from "jwt-decode";
import { Assign } from "utility-types";
import { differenceInMinutes } from "date-fns";
import { useAuthStore } from "stores/auth";
import { DOMAIN } from "../constants";
import { logout } from "App";

interface Params {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  headers?: { [key: string]: string };
  data?: unknown;
  origin?: string;
  abortToken?: string;
  mode?: "navigate" | "same-origin" | "no-cors" | "cors";
  responseType?: "arraybuffer" | "blob";
  tokenMiddleware?: boolean;
}

export type SearchParams = { [key: string]: any };

export interface BackendErrorDict {
  [key: string]: any;
}

export type BackendError = BackendErrorDict;

/**
 * returns value of <html> lang attribute
 */
function getLang() {
  return "pl";
}

/**
 * function for use only in api middleware.
 * It's a helper to format api 400 response in a better way that can
 * be used directly to display errors in form
 */
function flattenErrors(error: BackendError) {
  if (!error) return {};

  const errors: { [key: string]: any } = {};
  if (Array.isArray(error)) {
    if (typeof error[0] === "string") {
      errors.message = error[0];
    }
    return errors;
  }

  Object.keys(error).forEach((key) => {
    if (typeof error[key] === "string") {
      errors[key] = error[key];
    } else if (error[key] instanceof Array) {
      if (error[key][0] instanceof Object) {
        errors[key] = error[key].map((err: BackendError) => flattenErrors(err));
      } else {
        const [firstKey] = error[key];
        errors[key] = firstKey;
      }
    } else if (error[key] instanceof Object) {
      errors[key] = error[key];
    }
  });

  return errors;
}

/**
 * removes duplicate slashes
 */
function removeSlash(url: string) {
  const arr = url.split("?");
  if (arr[0].charAt(0) === "/") {
    arr[0] = arr[0].substring(1);
    const search = arr[1] ? "?" + arr[1] : "";
    return arr[0] + search;
  }
  return url;
}

export interface Error {
  [key: string]: string;
}
interface Statuses {
  status: number;
  isCanceled: boolean;
  rawError?: any;
}

const tokenMeta = {
  accessToken: {
    token: "",
    expire: 0,
  },
  refreshToken: {
    token: "",
    expire: 0,
  },
};

/**
 * Function refreshes access token if needed;
 * Function logs out the user if the refresh token has expired;
 * It is important to multiply expiration date by 1000, because there is inconsistency
 * in data returned from backend and browser date API - backend works on seconds,
 * date API works on milliseconds.
 * Be careful not to multiply twice!
 */
const tokenRefresher = async (accessToken: string) => {
  if (tokenMeta.accessToken.token !== accessToken) {
    const refreshToken = localStorage.getItem("refreshToken") as string;
    const refreshDecoded = jwtDecode<{ exp: number; userId: number }>(refreshToken);
    const accessDecoded = jwtDecode<{ exp: number; userId: number }>(accessToken);
    tokenMeta.accessToken.token = accessToken;
    tokenMeta.accessToken.expire = accessDecoded.exp * 1000;
    tokenMeta.refreshToken.token = refreshToken;
    tokenMeta.refreshToken.expire = refreshDecoded.exp * 1000;
  }
  const now = new Date();

  function lessThan2MinutesToExpire() {
    const accessExpireDate = tokenMeta.accessToken.expire;
    const accessMinutesLeft = differenceInMinutes(new Date(accessExpireDate), now);
    // if less than 2 minutes left for token to expire
    if (accessMinutesLeft <= 1) {
      return true;
    }
    return false;
  }
  /**
   * Function checks if refresh token has expired
   */
  function refreshTokenExpired() {
    const exp = tokenMeta.refreshToken.expire;
    const hasExpired = differenceInMinutes(new Date(exp), now) <= 1;
    return hasExpired;
  }
  /**
   * Function refreshes access token with refresh token and stores it in localStorage
   */
  async function fetchAndStoreToken() {
    return new Promise<"success" | "failure">(async (resolve) => {
      const refreshToken = tokenMeta.refreshToken.token;

      const res = await queryFetch<{ access: string }>({
        method: "POST",
        url: "/users/token/refresh",
        tokenMiddleware: false,
        data: { refresh: refreshToken },
      });

      if (res) {
        const accessDecoded = jwtDecode<{ exp: number }>(res.access);
        localStorage.setItem("token", res.access);
        tokenMeta.accessToken.token = res.access;
        tokenMeta.accessToken.expire = accessDecoded.exp * 1000;
        resolve("success");
      } else {
        resolve("failure");
      }
    });
  }

  return new Promise<"success" | "failure">(async (resolve) => {
    const tokenNeedsRefresh = lessThan2MinutesToExpire();
    if (tokenNeedsRefresh === false) {
      resolve("success");
    } else {
      const refreshTokenHasExpired = refreshTokenExpired();
      if (refreshTokenHasExpired) {
        const {
          actions: { clear },
        } = useAuthStore.getState();

        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        tokenMeta.accessToken.token = "";
        tokenMeta.accessToken.expire = 0;
        tokenMeta.refreshToken.token = "";
        tokenMeta.refreshToken.expire = 0;
        clear();

        resolve("failure");
      } else {
        const status = await fetchAndStoreToken();

        resolve(status);
      }
    }
  });
};

export type ApiMiddlewareResult<P> = Promise<[P, null, Statuses] | [null, Error | null, Statuses]>;

export async function queryFetch<P>({
  url,
  method = "GET",
  headers = {},
  params = {},
  data,
  origin,
  tokenMiddleware = true,
}: Assign<Params, { params?: SearchParams }>): Promise<P> {
  if (tokenMiddleware && tokenMiddlewareController.promise) {
    await tokenMiddlewareController.promise;
  }

  const currentToken = localStorage.getItem("token");

  if (currentToken && tokenMiddleware) {
    tokenMiddlewareController.promise = new Promise<void>((resolve) => {
      tokenMiddlewareController.resolve = resolve;
    });
    const tokenRefreshStatus = await tokenRefresher(currentToken);
    tokenMiddlewareController.promise = null;
    tokenMiddlewareController.resolve?.();

    if (tokenRefreshStatus === "failure") {
      logout();
      return Promise.reject();
    }
  }

  const allHeaders: { [key: string]: string } = {
    "Accept-Language": getLang(),
    ...headers,
  };
  const token = localStorage && localStorage.token;
  const endpoint = (origin || DOMAIN) + removeSlash(url);
  if (token && tokenMiddleware) {
    // if user is logged in - add token to request headers
    allHeaders.Authorization = "Bearer " + tokenMeta.accessToken.token;
  }
  if (data instanceof FormData === false) {
    allHeaders["Content-Type"] = "application/json";
  }

  const source = axios.CancelToken.source();
  const promise = axios(endpoint, {
    cancelToken: source.token,
    data: (() => {
      if (!data) return undefined;
      if (data instanceof FormData) return data;
      return JSON.stringify(data);
    })(),
    headers: allHeaders,
    method,
    params,
  })
    .then((el) => el.data)
    .catch((err: AxiosError) => {
      throw flattenErrors(err.response?.data);
    });

  //@ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled by React Query");
  };

  return promise;
}

const tokenMiddlewareController: { promise: Promise<any> | null; resolve: (() => void) | null } = {
  promise: null,
  resolve: null,
};
