export const COLORS = { red: "#e6007c", white: "#fff" };

export const DOMAIN = (() => {
  const server = process.env.REACT_APP_SERVER as
    | "development"
    | "nanobiomeDev"
    | "nanobiomeProd"
    | "local"
    | undefined;

  if (server === "local") return "http://localhost:8000/";
  if (server === "nanobiomeProd") return "https://api.nanobiome.pl/";
  if (server === "nanobiomeDev") return "https://genx1.pl/";
  if (server === "development") return "https://nanobiome.api.kivy.pl/";

  return "https://nanobiome.api.kivy.pl/";
})();

export const RECAPTCHA_KEY = (() => {
  const server = process.env.REACT_APP_SERVER as
    | "development"
    | "nanobiomeDev"
    | "nanobiomeProd"
    | "local"
    | undefined;

  if (server === "local") return "6Ld4-WodAAAAAPEoq6RhtshqwHayBeip3OR7VpY4";
  if (server === "nanobiomeProd") return "6LdWAqEdAAAAAFbenO_Y1uVY0xhpS8zOPv3Gu4eY";
  if (server === "nanobiomeDev") return "6LdWAqEdAAAAAFbenO_Y1uVY0xhpS8zOPv3Gu4eY";
  if (server === "development") return "6Ld4-WodAAAAAPEoq6RhtshqwHayBeip3OR7VpY4";

  return "6Ld4-WodAAAAAPEoq6RhtshqwHayBeip3OR7VpY4/";
})();
