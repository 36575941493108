import { DetailedHTMLProps, ForwardedRef, forwardRef, InputHTMLAttributes, ReactNode } from "react";
import styles from "./Checkbox.module.css";
import cx from "classnames";

type Props<T> = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  label: ReactNode;
  id?: string;
  name: keyof T;
  checked: boolean;
};

function CheckboxInner<T extends Record<string, any>>(
  { className, disabled, name, id = "input-" + name, label, ...rest }: Props<T>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <div className={cx(className, disabled ? "disabled" : undefined, styles.checkbox)}>
      <input
        type="checkbox"
        className={styles.input}
        ref={ref}
        id={id}
        name={name}
        disabled={disabled}
        {...rest}
      />
      <label htmlFor={id} className={styles.styledCheckbox}></label>
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
    </div>
  );
}
export const Checkbox = forwardRef(CheckboxInner) as typeof CheckboxInner;
