import { useHistory, useParams } from "react-router-dom";
import "survey-react/survey.css";
import styles from "../reports/Reports.module.css";
// import tstyles from "./Table.module.css";
import { cx } from "utilities/cx";
import { TableFrame } from "./TableFrame";
import { useMemo, useRef } from "react";
import { PrintPdfButton } from "pages/reports/ReportPdf";
import { useReport, useTable } from "api/reports/hooks";
import { reportKeys } from "api/reports/keys";
import { getAnyErrorKey } from "utilities/getAnyErrorKey";
import { useToastr } from "components/utils/toastr/ToastrController";
import { fileFactory } from "api/reports/calls";
import { fileDownloader } from "fileDownloader";
import { useAuthStore } from "stores/auth";
// import { useQuery } from "components/utils/queryProvider/useQuery";

function useContainerSize() {
  const ref = useRef<HTMLDivElement | null>(null);

  const refStyle = ref.current ? window.getComputedStyle(ref.current) : null;

  const size = useMemo(
    () =>
      ref.current && refStyle
        ? {
            width:
              ref.current.offsetWidth -
              parseFloat(refStyle.paddingLeft) -
              parseFloat(refStyle.paddingRight),
            height:
              ref.current.offsetHeight -
              parseFloat(refStyle.paddingTop) -
              parseFloat(refStyle.paddingBottom),
          }
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // console.log(ref, size);
  return [ref, size] as const;
}

export const Table = () => {  
  // const { query, updateQuery } = useQuery();
  const { code } = useParams<{ code: string }>();
  const history = useHistory();
  const toastr = useToastr();
  const me = useAuthStore((store) => store.user);
  const [ref, size] = useContainerSize();


  const { data: tableData, isLoading: inProgressT } = useTable(reportKeys.table(code), String(code), {
    onError: (err) => {
      toastr.neutral({
        title: "table error",
        text: getAnyErrorKey(err),
      });
      history.goBack();
    },
  });
  const { data: report, isLoading: inProgress } = useReport(reportKeys.report(code), String(code), {
    onError: (err) => {
      toastr.neutral({
        title: "",
        text: getAnyErrorKey(err),
      });
      history.goBack();
    },
  });
  if (inProgress || inProgressT) {
    return <div>Ładowanie raportu</div>;
  }

  if (!report) {
    return <div>Brak raportu</div>;
  }  
  if (!tableData) {
    return <div>Brak tabeli</div>;
  }


  return (
    <div className="d-flex align-items-start justify-content-center">
      <div className="mainContainer">
        <div className="row mt-5">
          <div className="d-flex align-items-end justify-content-end">
            <div className="pb-1 cursor-pointer" onClick={() => history.push("/")}>
              {"<"} Powrót do pulpitu
            </div>
          </div>
          <div className="col-12 col-md-3 mb-4 pe-0">
            <div className={cx("d-flex flex-column justify-content-between p-4", styles.navBox)}>
              <h2 className="pb-5 mb-5">Raport</h2>
              <nav className="mb-4">
                {report && report.data.map(({ page, title }) => (
                  <div
                    className={styles.navLink}
                    onClick={() => {
                      history.push(`/reports/${code}?page=${page}`);
                    }}
                  >
                    {title}
                  </div>
                ))}
              </nav>
              
                <div className="d-flex flex-column align-items-start mt-4 pt-2">
                  <button
                    className="btnSecondary px-2 mb-2"
                    onClick={() => history.push(`/reports/${code}`)}
                  >
                    Raport
                  </button>
                  <PrintPdfButton report={report} />
                </div>
              
              <button
                className="btnSecondary px-2 mb-2"
                onClick={() => history.push(`/krona/${code}`)}
              >
                Interaktywna przeglądarka mikrobiomu
              </button>
            </div>
          </div>
          <div className="col-12 col-md-9 ps-0">
            <div className={cx("px-4", styles.reportBox)} ref={ref}>
              <div className="content mb-4" style={{ overflow: "visible", maxWidth: "90vw" }}>
                {tableData && <TableFrame tableData={tableData} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
