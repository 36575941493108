// import cuid from "cuid";
import { useField } from "formik";
import { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { cx } from "../../../utilities/cx";
import { Text } from "../text/Text";
import { FieldError } from "./FieldError";

type Props<V extends Record<string, any>> = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  name: keyof V | `${string}.${string}`;
  label?: string;
  inputClassName: string;
};

export function Field<V extends Record<string, any>>({
  name,
  label,
  style,
  className,
  inputClassName,
  ...rest
}: Props<V>) {
  const _name = name as string;
  const [inputProps] = useField(_name);
  if (inputProps.value === undefined) return null;

  return (
    <div className={cx("mb-3", className)} style={style}>
      {label && (
        <Text fontSize="12" as="label" htmlFor={_name} className="mb-1">
          {label}
        </Text>
      )}
      <input
        {...rest}
        style={{ display: "block" }}
        className={inputClassName}
        id={_name}
        name={name}
        onChange={inputProps.onChange}
        onBlur={inputProps.onBlur}
        value={inputProps.value}
        aria-autocomplete="both"
        aria-haspopup="false"
        autoComplete="off"
        autoCorrect="off"
        autoFocus={false}
        spellCheck="false"
      />
      <FieldError<V> name={name} />
    </div>
  );
}
