import { queryFetch } from "api/connector";
import { PostSample, Sample } from "./models";

export const postSample = (data: PostSample) =>
  queryFetch<Sample>({
    method: "POST",
    url: "/samples/items",
    data,
  });

export const getSamples = () =>
  queryFetch<Sample>({
    method: "GET",
    url: "/samples/items",
  });
