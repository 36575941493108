import styles from "./ReportPdf.module.css";
import m1 from "../../assets/images/m1.png";
import m2 from "../../assets/images/m2.png";
import m3 from "../../assets/images/m3.png";
import m4 from "../../assets/images/m4.png";
import m5 from "../../assets/images/m5.png";
import { useParams } from "react-router-dom";
import React from "react";
import { cx } from "utilities/cx";
import { useRef } from "react";
import { Report } from "api/reports/models";
import bacteriaImg from "../../assets/images/bakterid_ikoonid.svg";
import markerImg from "../../assets/images/marker.svg";
import NBlogoB from "../../assets/images/logopng1.png";
import NbsygnetC from "../../assets/images/logonano.png";
import { PDFExport } from "@progress/kendo-react-pdf";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { dateFns } from "utilities/dateFns";
import {
  indexToColorDict,
  page4IconsDict,
  page4MainIcons,
  recommendationIconDict,
  printNumberReport,
} from "./constants";
import chart from "../../assets/images/version1/enterotype_plot.svg";
import obesityPreventWave from "../../assets/images/version1/obesityminusWave.svg";
import obesityFavorableWave from "../../assets/images/version1/obesityWave.svg";

import { fileDownloader } from "fileDownloader";
import { fileFactory } from "api/reports/calls";
// import { stableValueHash } from "react-query/types/core/utils";
import { Pie } from 'react-chartjs-2';
import Chart from "react-apexcharts";
import { htmlRender } from "utilities/htmlRender";


const BG_PLOT_WIDTH = 95;
const LEFT_BG_MARGIN = 2;
const PUT_STAR_WHEN_OVER = 99.9;
const ICONSINROW = 6;

ChartJS.register(ArcElement, Tooltip, Legend);

const PageHeader = ({ title }: { title: string }) => {
  return (
    <div className={styles.pageHeader}>
      <div className="d-flex flex-column justify-content-end">
        <div className={cx(styles.logo, styles.mb3)}>
          <img src={NBlogoB} alt="logo" />
        </div>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={cx(styles.logoRight)}>
        <img src={NbsygnetC} alt="logo" />
      </div>
    </div>
  );
};

export const PrintPdfButton = ({ report }: { report: Report }) => {
  const pdfExportComponent = useRef(null);
  const { code } = useParams<{ code: string }>();

  // const handleExportWithComponent = () => {
  //   //@ts-ignore
  //   pdfExportComponent.current.save();
  // };


  async function handleExportWithComponent() {
     const { url, name } = fileFactory.reportPdf(code);
     const response = await fileDownloader({
       url,
       name: `raport-${dateFns.format(new Date(report.sampleDate), "yyyy.MM.dd")}`,
       type: "pdf",
     });
     if (response.status === "success") {
     } else {
      //@ts-ignore
       pdfExportComponent.current.save();
     }
  };

  const sortedReports = (() => {
    function compare(
      a: Report["data"][number]["sections"][number],
      b: Report["data"][number]["sections"][number],
    ) {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    }

    const sortedPages = report.data.reduce(
      (acc: Report["data"], el: Report["data"][number], index: number) => {
        var section1, section2, section3, section4;
        if ((el.title === "Bakterie w Twoim jelicie") || (el.title === "Twój obecny styl życia na podstawie ankiety")) {
          if ((el.title === "Bakterie w Twoim jelicie")) {
            section1 = el.sections.slice(0, 5);
            section2 = el.sections.slice(5, 9);
            section3 = el.sections.slice(9, 13);
            section4 = el.sections.slice(13);
          } else {
            section1 = el.sections.slice(0, 4);
            section2 = el.sections.slice(4, 8);
            section3 = el.sections.slice(8, 12);
            section4 = el.sections.slice(12);
          }
          acc.push({
            ...el,
            //@ts-ignore
            sections: [...section1.sort(compare)],
          });
          acc.push({
            ...el,
            //@ts-ignore
            sections: [...section2.sort(compare)],
          });

          acc.push({
            ...el,
            //@ts-ignore
            sections: [...section3.sort(compare)],
          });
          if (el.sections.length > 13) {
            acc.push({
              ...el,
              //@ts-ignore
              sections: [...section4.sort(compare)],
            });
          };

        } else {
          acc.push({
            ...el,
            sections: el.sections.sort(compare),
          });
        }

        return acc;
      },
      [],
    );

    return sortedPages;
  })();

  return (
    <div>
      <button className="btnPrimary mb-2" onClick={() => handleExportWithComponent()}>
        Pobierz raport
      </button>
      <div
        style={{
          position: "absolute",
          left: "-800000px",
          top: "-800000px",
        }}
      >
        <PDFExport
          fileName={`raport-${dateFns.format(new Date(report.sampleDate), "yyyy.MM.dd")}.pdf`}
          ref={pdfExportComponent}
          paperSize="A4"
          margin="0.5cm"
          scale={1 / 1.33}
          creator="GenXone SA"
          imageResolution={800}
        >
          <React.Fragment>
            {sortedReports.map((el, index) => (
              <div className={cx(styles.singlePage, "page-break")} key={el.page}>
                {el.title !== "Słowniczek" ? 
                <div>
                  <PageHeader title={el.title} />
                  <div className={(styles.content, "row")}>
                    {el.sections.map((section) => {
                      if (section.type === "paragraph") {
                        return (
                          <div
                          >

                          <div className="row">
                            {section.title && (
                              <div className={cx(styles.mb1)}>
                                <h3
                                  className={cx(
                                    styles.boldTitle,
                                    styles.smallTitle,
                                    styles.mb1,
                                    styles.mt3,
                                  )}
                                >
                                  {section.title}
                                </h3>
                                <div
                                  // dangerouslySetInnerHTML={{ __html: section.value }}
                                  className={cx(styles.me3, styles.my0, styles.subTitle)}
                                  />{htmlRender(section.value)} 
                              </div>
                            )}
                              <div
                                className={
                                  section.diagram?.type === "dominant_types" ? "col-6" : "col-12"
                                }
                              >
                                {section.description.map((el) => (
                                  <div>
                                    <div className={styles.my2} />

                                    <div
                                      className={cx(styles.description, styles.my1, section.diagram?.type === "dominant_types" ? styles.me4 : styles.me0)}
                                      >{htmlRender(el )}</div>
                                  </div>
                                ))}
                              </div>
                        {(el.page === 1 && section.order === 0) && (
                          <div className={cx("col-12 my-2", styles.infographic)}>
                            <img src={bacteriaImg} alt="grafika" />
                          </div>
                        )}
                              {section.diagram && section.diagram.type === "dominant_types" && (
                                <>
                                  <div className="col-6">
                                    <div className={styles.pieChartContainer}>
                                      <Doughnut
                                        options={{ cutout: 129 }}
                                        data={{
                                          datasets: [
                                            {
                                              data: section.diagram.values.map((el) => el.value),
                                              backgroundColor: Object.values(indexToColorDict),
                                              borderColor: Object.values(indexToColorDict),
                                            },
                                          ],
                                        }}
                                      />
                                      <div className={styles.legenda}>
                                        {section.diagram.values.map(({ value, name }, index) => (
                                          <div className={styles.entry}>
                                            <div
                                              className={cx(styles.entryColor)}
                                              style={{ background: indexToColorDict[index] }}
                                            ></div>
                                            <div className={styles.entryText}>
                                              {name} {value >= 0.05 ? `${value.toFixed(2)}%` : "< 0.05%"}
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {section.diagram?.type === "dominant_kinds" && (
                                <div className="col-12">
                                  <div className={styles.stackedBarChartBox}>
                                    <div className={styles.stackedBarChart}>
                                      <div className={styles.stackedBar}>
                                        {section.diagram.values.map(({ value, name }, index) => (
                                          <span
                                            className={styles.value}
                                            style={{ width: `${value}%` }}
                                          >
                                            {value > 5 ? `${value.toFixed(2)}%` : ""}
                                          </span>
                                        ))}
                                      </div>
                                    </div>
                                    <div className={styles.stackedBarLegend}>
                                      {section.diagram.values.map(({ value, name }, index) => (
                                        <div>
                                          <span className={styles.value}></span>
                                          <span className={styles.label}>
                                              {name}: {value.toFixed(2)}%
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {section.diagram?.type === "dominance5" && (
                                <div className="col-12">
                                  <div className="d-flex align-items-center">
                                    <div className={cx(styles.italicTitle, "mt-4", "me-2")}>
                                      {section.diagram.title}
                                    </div>
                                  </div>
                                  <div className={styles.speciesdiv}>
                                    <div className={styles.species}>
                                      <strong>{section.diagram.values.toFixed(1)}%</strong>
                                    </div>
                                    <div className={styles.dominanceContainer}>
                                      <Pie data={{
                                        labels: ['5 najczęstszych\n gatunków', 'pozostałe'],
                                        datasets: [
                                          {
                                            data: [section.diagram.values.toFixed(1), (100-section.diagram.values).toFixed(1)],
                                            backgroundColor: [
                                              '#b1235c', '#999'
                                            ],
                                            borderWidth: 1,
                                          }
                                        ]
                                        }}
                                        options={{ 
                                          plugins: {  
                                            legend: { display: true, position: "right" , fullSize: false,
                                            maxWidth: 340, 
                                              labels: { font: {  size: 14, family: "Rubik" } },
                                            },

                                          }, 
                                          aspectRatio: 4,
                                          events: ['mousemove'],
                                        }}
                                      />
                                    </div>
                                  </div>
                              </div>)}
                              {section.diagram?.type === "number_of_species" && (
                                <div className="col-12">
                                    <div className="d-flex align-items-center">
                                      <div className={cx(styles.italicTitle, "mt-4", "me-2")}>
                                        {section.diagram.title}
                                      </div>
                                    </div>
                                    <div className={styles.speciesdiv}>
                                      <div className={styles.species}>
                                        <strong>{section.diagram.values}</strong>
                                      </div>
                                    </div>
                                </div>
                              )} 
                              {section.diagram?.type === "number_of_species_range" && (
                                <div className="col-12">
                                    <div className="d-flex align-items-center">
                                      <div className={cx(styles.italicTitle, "mt-4", "me-2")}>
                                        {section.diagram.title}
                                      </div>
                                    </div>
                                    <div className={styles.speciesdiv}>
                                      <div className={styles.species}>
                                        <strong>{section.diagram.result} </strong>
                                      </div>
                                      <div className={styles.speciesdesc}>
                                        Zakres wartości obserwowany w bazie odniesienia: {section.diagram.referenceMin} -  {section.diagram.referenceMax}
                                      </div>
                                    </div>
                                </div>
                              )} 
                              
                            </div>
                          </div>
                        );
                      }

                      if (section.type === "recommendation") {
                        return (
                          <div className={cx(styles.mb1, styles.mt2, "col-12")}>
                            <div className="row">
                              <div style={{ width: "50px" }}>
                                <img
                                  src={recommendationIconDict[section.diagram.mainIcon]}
                                  alt=""
                                />
                              </div>
                              <div style={{ width: "calc(100% - 50px)" }}>
                                {section.description.map((el) => (
                                  <div
                                    className={cx(styles.description, styles.my1)}
                                    >{htmlRender(el)}</div>
                                ))}
                              </div>
                            </div>
                          </div>
                        );
                      }

                      if (section.type === "poll_result") {
                        return (
                          <div className={cx(styles.mb1, section.diagram.type === "poll_result_summary" ? "col-12" : "col-6")}>
                            <div className="d-flex align-items-center">
                              <div
                                className={cx(
                                  styles.me3,
                                  styles.my0,
                                  styles.mt2,
                                  styles.smallTitle,
                                  styles.subTitle,
                                )}
                              >
                                {section.title}
                              </div>

                              {section.diagram.type === "poll_result_bmi" && (
                                  <div className={styles.resultbmi}>
                                    {section.diagram.values[1].toFixed(1)}kg/m<sup>2</sup>
                                  </div>
                                )}
                            </div>

                            {section.diagram.type !== "poll_result_summary" && (
                              <div className="mb-2">
                                <div className={styles.scaleChartBox}>
                                  {section.diagram.type === "poll_result" && (<div className={styles.scaleChartBar}>
                                    <span className={styles.divider}></span>
                                    <span className={styles.divider}></span>
                                  </div>)}
                                  {section.diagram.type === "poll_result_exercises" && (<div
                                      className={cx(styles.scaleChartBar, styles.gradientActivity)}
                                  ></div>)}
                                  {section.diagram.type === "poll_result_alcohol" && (<div
                                      className={cx(styles.scaleChartBar, styles.gradientAlcohol)}
                                  ></div>)}
                                  {section.diagram.type === "poll_result_unhealthy" && (<div
                                      className={cx(styles.scaleChartBar, styles.gradientUnhealthy)}
                                  ></div>)}
                                  {section.diagram.type === "poll_result_redgreen" && (<div
                                      className={cx(styles.scaleChartBar, styles.gradientRedGreen)}
                                  ></div>)}
                                  {section.diagram.type === "poll_result_bmi" && (<div
                                      className={cx(styles.scaleChartBar, styles.gradientBMI)}
                                  ></div>)}
                                  <img
                                    className={styles.scaleChartMarker}
                                    style={{
                                      left: `${
                                        section.diagram.values[0] > 98 ? 98 : section.diagram.values[0] //- 2.5
                                      }%`,
                                    }}
                                    src={markerImg}
                                    alt=""
                                  />
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className={styles.scaleChartLabel}>
                                      {section.diagram.leftBandText}
                                    </div>
                                    {section.diagram?.center && (<div className={styles.scaleChartLabel}>
                                      {section.diagram.center}
                                    </div>)}
                                    <div className={styles.scaleChartLabel}>
                                      {section.diagram.rightBandText}
                                    </div>
                                  </div>
                                </div>
                              
                              <div className="col-12">
                                {section.description.map((el) => (
                                  <div
                                    className={cx(styles.description, styles.my1)}
                                    >{htmlRender(el)}</div>
                                ))}
                              </div>
                            </div>)} 
                            
                            {section.diagram.type === "poll_result_summary" && (
                            <div className={cx(styles.mb1, "col-12")}>
                              <div className="col-12">
                                  {section.description.map((el) => (
                                    <div className={cx(styles.description, styles.my1)}
                                    >{htmlRender(el)}</div>
                                  ))}
                              </div>
                              <div className="col-12">
                                <div  className="d-flex align-items-center justify-content-between">
                                  <div className={styles.RadarChartArea}>
                                    <div className={styles.RadarChartBox}>
                                      <Chart  type='radar' width='500px'
                                          style={{fontFamily: "Rubik"}}
                                          series={[{name: "Twój wynik", data: Object.values(section.diagram.pollvalues), color: '#3c396b'}]}
                                          options={{
                                            grid: { show: false, borderColor: "transparent"},
                                            chart: {
                                                  type: 'radar',
                                                  toolbar: {show: false},
                                                  background: '#ffffff'
                                            },
                                            plotOptions: {
                                                  radar: {
                                                  size: 120,
                                                  polygons: {
                                                    fill: {
                                                      colors: ['#009688', '#99c95a', '#ffeb3b', '#fa9738', '#f44336']},
                                                    }
                                                  },
                                            },
                                            markers: { size: 4 },
                                            dataLabels: {  enabled: false  },
                                            xaxis: {
                                              categories: Object.values(section.diagram.captions),
                                              labels: {
                                                style: { fontSize: '13px',
                                                fontFamily: 'Rubik', 
                                                colors: ['#555555', '#555555', '#555555', '#555555', '#555555', '#555555', '#555555', '#555555', '#555555', '#555555', '#555555', ]
                                              }}
                                            },
                                            yaxis: {min: -5, max: 100, tickAmount: 5, show: false, labels: {show: false}}
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      }

                      if (section.type === "lab_result") {
                        return (
                          <div className={cx(styles.my3, "col-6")}>
                            <div className="col-12">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center justify-content-between">
                                  <img src={page4MainIcons[section.diagram.mainIcon].icon} alt="" />
                                  <div className={styles.subTitle}>{section.title}</div>
                                </div>
                                {section.diagram.type === "lab_result" && (
                                  <div className={styles.result}>
                                    <strong>{section.diagram.values.perc.toFixed(2)}</strong> %
                                  </div>
                                )}
                              </div>
                              <div>
                                {section.diagram.type === "double_lab_result" && (
                                  <div className="d-flex align-items-baseline justify-content-end">
                                    <div className={cx(styles.resultLabel, styles.me2)}>
                                      Przeciwdziałające otyłości
                                    </div>
                                    <div className={styles.result}>
                                      <strong>{section.diagram.plus.perc.toFixed(2)}</strong> %
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className={cx(styles.my1, "col-12")}>
                              {section.diagram.type === "lab_result" && (
                                <div className={styles.mb1}>
                                  <div className={styles.barChartBox}>
                                    <div className={styles.barChartBar}>
                                      <img
                                        className={styles.wave}
                                        src={page4MainIcons[section.diagram.mainIcon].diagram}
                                        alt=""
                                      />
                                    </div>
                                    <img
                                      className={styles.barChartMarker}
                                      style={{
                                        left: `${
                                          (section.diagram.values.perc * 100) /
                                            section.diagram.maxValue >
                                            PUT_STAR_WHEN_OVER
                                              ? BG_PLOT_WIDTH + LEFT_BG_MARGIN
                                              : (section.diagram.values.perc * BG_PLOT_WIDTH) /
                                                section.diagram.maxValue + LEFT_BG_MARGIN
                                        }%`,
                                        bottom: "-5px",
                                      }}
                                      src={markerImg}
                                      alt=""
                                    />
                                    {(section.diagram.values.perc * 100) /
                                      section.diagram.maxValue >
                                      PUT_STAR_WHEN_OVER && <div className={styles.star}>*</div>}
                                    <div
                                      className={cx(
                                        styles.barChartLabelsBox,
                                        "d-flex justify-content-between",
                                      )}
                                    >
                                      <div
                                        className={cx(
                                          styles.barChartLabel,
                                          styles.barChartLabelLeft,
                                        )}
                                      >
                                        {section.diagram.minValue.toFixed(2)}%
                                      </div>
                                      <div
                                        className={cx(
                                          styles.barChartLabel,
                                          styles.barChartLabelRight,
                                        )}
                                      >
                                        {section.diagram.maxValue.toFixed(2)}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {section.diagram.type === "double_lab_result" && (
                                <div className={styles.mb1}>
                                  <div className={styles.barChartBox}>
                                    <div className={styles.barChartBar}>
                                      <img
                                        className={styles.wave}
                                        src={obesityPreventWave}
                                        alt=""
                                      />
                                    </div>
                                    <img
                                      className={styles.barChartMarker}
                                      style={{
                                        left: `${
                                          (section.diagram.plus.perc * 100) /
                                            section.diagram.maxValuePlus >
                                            PUT_STAR_WHEN_OVER
                                            ? BG_PLOT_WIDTH + LEFT_BG_MARGIN
                                            : (section.diagram.plus.perc * BG_PLOT_WIDTH) /
                                              section.diagram.maxValuePlus + LEFT_BG_MARGIN
                                        }%`,
                                        bottom: "-5px",
                                      }}
                                      src={markerImg}
                                      alt=""
                                    />
                                    {(section.diagram.plus.perc * 100) /
                                      section.diagram.maxValuePlus >
                                      PUT_STAR_WHEN_OVER && <div className={styles.star}>*</div>}
                                    <div
                                      className={cx(
                                        styles.barChartLabelsBox,
                                        "d-flex justify-content-between",
                                      )}
                                    >
                                      <div
                                        className={cx(
                                          styles.barChartLabel,
                                          styles.barChartLabelLeft,
                                        )}
                                      >
                                        {section.diagram.minValuePlus.toFixed(2)}%
                                      </div>
                                      <div
                                        className={cx(
                                          styles.barChartLabel,
                                          styles.barChartLabelRight,
                                        )}
                                      >
                                        {section.diagram.maxValuePlus.toFixed(2)}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {section.diagram.type === "double_lab_result" && (
                                <div>
                                  <div className="d-flex justify-content-end align-items-baseline">
                                    <div className={cx(styles.resultLabel, styles.me2)}>
                                      Sprzyjające otyłości
                                    </div>
                                    <div className={styles.result}>
                                      <strong>{section.diagram.minus.perc.toFixed(2)}</strong> %
                                    </div>
                                  </div>
                                  <div className={styles.mb1}>
                                    <div className={styles.barChartBox}>
                                      <div className={styles.barChartBar}>
                                        <img
                                          className={styles.wave}
                                          src={obesityFavorableWave}
                                          alt=""
                                        />
                                      </div>
                                      <img
                                        className={styles.barChartMarker}
                                        style={{
                                          left: `${
                                            (section.diagram.minus.perc * 100) /
                                              section.diagram.maxValueMinus >
                                              PUT_STAR_WHEN_OVER
                                              ? BG_PLOT_WIDTH + LEFT_BG_MARGIN
                                              : (section.diagram.minus.perc * BG_PLOT_WIDTH) /
                                                section.diagram.maxValueMinus + LEFT_BG_MARGIN
                                          }%`,
                                          bottom: "-5px",
                                        }}
                                        src={markerImg}
                                        alt=""
                                      />
                                      {(section.diagram.minus.perc * 100) /
                                        section.diagram.maxValueMinus >
                                        PUT_STAR_WHEN_OVER && <div className={styles.star}>*</div>}

                                      <div
                                        className={cx(
                                          styles.barChartLabelsBox,
                                          "d-flex justify-content-between",
                                        )}
                                      >
                                        <div
                                          className={cx(
                                            styles.barChartLabel,
                                            styles.barChartLabelLeft,
                                          )}
                                        >
                                          {section.diagram.minValueMinus.toFixed(2)}%
                                        </div>
                                        <div
                                          className={cx(
                                            styles.barChartLabel,
                                            styles.barChartLabelRight,
                                          )}
                                        >
                                          {section.diagram.maxValueMinus.toFixed(2)}%
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="col-12">
                              {section.description.map((el) => (
                                <div
                                  className={cx(styles.my3, styles.description)}
                                  >{htmlRender(el)}</div>
                              ))}
                              <div className="d-flex align-items-start">
                                {section.diagram.icons.slice(0,ICONSINROW).map((icon, index) => (
                                  <div
                                    className={cx(
                                      styles.me1,
                                      "d-flex flex-column align-items-center",
                                    )}
                                  >
                                    <div>
                                      <img max-width="40px"
                                        src={page4IconsDict[icon] ? page4IconsDict[icon].icon : ""}
                                        alt=""
                                      />
                                    </div>
                                    <div className={styles.iconLabel}>
                                      {page4IconsDict[icon]
                                        ? page4IconsDict[icon].label.toUpperCase()
                                        : ""}
                                    </div>
                                  </div>
                                ))}
                              </div>

                              {section.diagram.icons.length > ICONSINROW ? 
                              <div className="d-flex align-items-start">
                                {section.diagram.icons.slice(ICONSINROW).map((icon, index) => (
                                  <div
                                    className={cx(
                                      styles.me1,
                                      styles.mt2,
                                      "d-flex flex-column align-items-center",
                                    )}
                                  >
                                    <div>
                                      <img max-width="40px"
                                        src={page4IconsDict[icon] ? page4IconsDict[icon].icon : ""}
                                        alt=""
                                      />
                                    </div>
                                    <div className={styles.iconLabel}>
                                      {page4IconsDict[icon]
                                        ? page4IconsDict[icon].label.toUpperCase()
                                        : ""}
                                    </div>
                                  </div>
                                ))}
                              </div>: null}
                            </div>
                          </div>
                        );
                      }
                      if (section.type === "info") {
                        return (
                          <div
                            className={cx("row", styles.mb2, styles.mt4, {
                              "": section?.diagram.type === "enterotype",
                            })}
                          >
                            <div className={cx(styles.mb1, "d-flex align-items-start")}>
                              <h3
                                className={cx(
                                  styles.boldTitle,
                                  styles.smallTitle,
                                  styles.mb2,
                                  styles.mt2,
                                )}
                              >
                                {section.title}
                              </h3>
                            </div>
                            <div
                              className={cx(styles.me3, styles.my0, styles.subTitle)}
                            >{htmlRender(section.value)}</div>

                            <div className="col-6">
                              {section.description.map((el) => (
                                <div>
                                <div className={styles.my2} />
                                  <div
                                    className={cx(styles.my1, styles.description)}
                                  >{htmlRender(el)}</div>
                                  
                                  {Boolean('hint' in section) && (
                                  <div
                                    className={cx(styles.my1, styles.description)}
                                    >{htmlRender(section.hint )}</div>
                                )}
                                </div>
                              ))}
                            </div>
                            {section.diagram?.type === "enterotype" && (
                              <div className="col-6">
                                <div className={styles.cloudsChartBox}>
                                  <div className={styles.cloudsChart}>
                                    <img src={chart} alt="" />
                                  </div>
                                  {(() => {
                                    if (section.diagram?.type === "enterotype") {
                                      const bounds = section.diagram.bounds;
                                      const xValue = section.diagram.values.x;
                                      const yValue = section.diagram.values.y;
                                      const isoutlier = Boolean('outlier' in section.diagram) && Boolean(section.diagram.outlier === "True");

                                      return (
                                        <div
                                          className={styles.cloudsChartMarker}
                                          style={{
                                            bottom: `${
                                              ((yValue - bounds.yRange.start) * 100) /
                                              (bounds.yRange.end - bounds.yRange.start)
                                            }%`,
                                            left: `${
                                              ((xValue - bounds.xRange.start) * 100) /
                                              (bounds.xRange.end - bounds.xRange.start)
                                            }%`,
                                          }}
                                        >
                                          <img src={markerImg} alt="" />
                                          {Boolean(isoutlier) && <div className={styles.cloudStar}>*</div>}
                                        </div>
                                      );
                                    } else {
                                      return null;
                                    }
                                  })()}
                                </div>
                                <div className={cx("", styles.cloudsChartLegend)}>
                                  <div className={cx(styles.legendInfo, "me-1 mb-1")}>
                                    <img src={m2} alt="" />
                                    <span className={cx(styles.label, "ps-2")}>Prevotella</span>
                                  </div>
                                  <div className={cx(styles.legendInfo, "me-1 mb-1")}>
                                    <img src={m1} alt="" />
                                    <span className={cx(styles.label, "ps-2")}>
                                      Prevotella + Firmicutes
                                    </span>
                                  </div>
                                  <div className={cx(styles.legendInfo, "me-1 mb-1")}>
                                    <img src={m5} alt="" />
                                    <span className={cx(styles.label, "ps-2")}>
                                      Firmicutes + Ruminococcus
                                    </span>
                                  </div>
                                  <div className={cx(styles.legendInfo, "me-1 mb-1")}>
                                    <img src={m4} alt="" />
                                    <span className={cx(styles.label, "ps-2")}>
                                     Firmicutes + Bacteroides
                                    </span>
                                  </div>
                                  <div className={cx(styles.legendInfo, "me-1 mb-1")}>
                                    <img src={m3} alt="" />
                                    <span className={cx(styles.label, "ps-2")}>Bacteroides</span>
                                  </div>
                                </div>
                              </div>
                            )}

                            {section.diagram && section.diagram.type === "diversity_index" && (
                              <div className="col-6">
                                <div className={styles.chartArea}>
                                  <div className={styles.halfDonutChartBox}>
                                    <div className={styles.halfDonutChart}>
                                      <div
                                        style={{
                                          transform: `rotate(${
                                            180 * Number(section.diagram.values[0]) - 2
                                          }deg)`,
                                        }}
                                      >
                                        <span></span>
                                      </div>
                                      <div
                                        style={{
                                          transform: `rotate(${
                                            180 * Number(section.diagram.values[0])
                                          }deg)`,
                                        }}
                                      >
                                        <span
                                          style={{
                                            transform: `rotate(${
                                              180 * Number(section.diagram.values[0]) > 90 ? 180 : -5
                                            }deg)`,
                                            marginTop: "0px",
                                          }}
                                        >
                                          {section.diagram.values[0].toFixed(2)}
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          transform: `rotate(${
                                            180 * section.diagram.values[2] - 2
                                          }deg)`,
                                        }}
                                      >
                                        <span></span>
                                      </div>
                                      <div
                                        style={{
                                          transform: `rotate(${
                                            180 * section.diagram.values[2] - 2 + 2
                                          }deg)`,
                                        }}
                                      >
                                        <span 
                                          style={{
                                            transform: `rotate(${
                                              180 * Number(section.diagram.values[2]) > 90 ? 180 : -5
                                            }deg)`,
                                            marginTop: "0px",
                                          }}>{section.diagram.values[2].toFixed(2)}</span>
                                      </div>
                                      <div
                                        style={{
                                          transform: "rotate(180deg)",
                                        }}
                                      >
                                        <span></span>
                                      </div>
                                      <div
                                        className={styles.marker}
                                        style={{
                                          transform: `rotate(${
                                            180 * section.diagram.values[1] 
                                          }deg)`,
                                        }}
                                      >
                                        <img
                                        className={styles.donutmarkerImg}                              
                                        src={markerImg}
                                        alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.halfDonutChartLegend}>
                                    <span>Twój wynik to:</span>
                                    <strong>{section.diagram.values[1].toFixed(2)}</strong>
                                  </div>
                                </div>

                                <div
                                  className={cx(styles.halfDonutChartLabelsContainer, "d-flex justify-content-between pt-2")}
                                  // style={{ width: "300px", margin: "0 auto" }}
                                >
                                  <div className={styles.chartLabel} style={{ paddingLeft: "10px" }}>
                                    <div>0</div>
                                  </div>
                                  <div className={styles.chartLabel} style={{ paddingLeft: "10px", paddingRight: "0px" }}>
                                    <div>1</div>
                                  </div>
                                </div>
                              </div>
                            )} 
                            {section.diagram && (section.diagram.type === "halfdonut" || section.diagram.type === "halfdonut_perc") && (
                              <div className="col-6">
                                <div className={styles.chartArea}>
                                  <div className={styles.halfDonutChartBox}>
                                    <div className={styles.halfDonutChart}>
                                      <div
                                        style={{
                                          transform: `rotate(${
                                            180 * Number(section.diagram.referenceMin)/Number(section.diagram.plotMax - section.diagram.plotMin) - 2
                                          }deg)`,
                                        }}
                                      >
                                        <span></span>
                                      </div>
                                      <div
                                        style={{
                                          transform: `rotate(${
                                            180 * Number(section.diagram.referenceMin)/Number(section.diagram.plotMax - section.diagram.plotMin)
                                          }deg)`,
                                        }}
                                      >
                                        <span
                                          style={{
                                            transform: `rotate(${
                                              180 * Number(section.diagram.referenceMin)/Number(section.diagram.plotMax - section.diagram.plotMin) > 90 ? -175 : -5
                                            }deg)`,
                                            marginTop: "0px",
                                          }}
                                        >
                                          {printNumberReport(section.diagram.referenceMin, section.diagram.type === "halfdonut_perc" )}
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          transform: `rotate(${
                                            180 * section.diagram.referenceMax/Number(section.diagram.plotMax - section.diagram.plotMin) - 2
                                          }deg)`,
                                        }}
                                      >
                                        <span></span>
                                      </div>
                                      <div
                                        style={{
                                          transform: `rotate(${
                                            180 * section.diagram.referenceMax/Number(section.diagram.plotMax - section.diagram.plotMin) - 2 + 2
                                          }deg)`,
                                        }}
                                      >
                                        <span 
                                          style={{
                                            transform: `rotate(${
                                              180 * Number(section.diagram.referenceMax)/Number(section.diagram.plotMax - section.diagram.plotMin) > 90 ? -175 : -5
                                            }deg)`,
                                            marginTop: "-5px",
                                          }}>{printNumberReport(section.diagram.referenceMax, section.diagram.type === "halfdonut_perc" )}</span>
                                      </div>
                                      <div
                                        style={{
                                          transform: "rotate(180deg)",
                                        }}
                                      >
                                        <span></span>
                                      </div>
                                      <div
                                        className={styles.marker}
                                        style={{
                                          transform: `rotate(${
                                            180 * section.diagram.result/Number(section.diagram.plotMax - section.diagram.plotMin)
                                          }deg)`,
                                        }}
                                      >
                                        <img className={styles.donutmarkerImg} src={markerImg} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.halfDonutChartLegend}>
                                    <span>Twój wynik to:</span>
                                    <strong>{printNumberReport(section.diagram.result, section.diagram.type === "halfdonut_perc" )}</strong>
                                  </div>
                                </div>

                                <div className={cx(styles.pt2, "d-flex justify-content-between", styles.halfDonutChartLabelsContainer)} >
                                  <div className={styles.chartLabel} style={{ paddingLeft: "8px" }}>
                                    <div>{printNumberReport(section.diagram.plotMin, section.diagram.type === "halfdonut_perc" )}</div>
                                  </div>
                                  <div className={styles.chartLabel} style={{ paddingLeft: "8px", paddingRight: "0px" }}>
                                    <div>{printNumberReport(section.diagram.plotMax, section.diagram.type === "halfdonut_perc" )}</div>
                                  </div>
                                </div>
                              </div>
                            )} 
                          </div>
                        );
                      }
                      return <div></div>;
                    })}
                  </div>
                </div> : null }
                <div className={styles.footer}>
                  <div
                    className={cx(
                      styles.footerText,
                      "d-flex align-items-center justify-content-between",
                    )}
                  >
                    <div>
                      Numer badania: {code}. Data pobrania próbki:{" "}
                      {dateFns.format(new Date(report.sampleDate), "yyyy.MM.dd")}{" "}. Data aktualizacji raportu:{" "}
                      {dateFns.format(new Date(report.lastModified), "yyyy.MM.dd")}{" "}
                    </div>
                    <div>strona {index + 1}</div>
                  </div>
                  <div className={styles.stripe}></div>
                </div>
              </div>
            ))}
          </React.Fragment>
        </PDFExport>
      </div>
    </div>
  );
};


