import cx from "classnames";
import { useField } from "formik";
import { Text } from "../text/Text";

export function FieldError<V extends Record<string, any>>({ name }: { name: keyof V }) {
  const [field, meta] = useField(name as string);
  const outOfFormScope = field.value === undefined;
  const showError = (meta.touched || outOfFormScope) && meta.error;

  return (
    <div className={cx({ "d-none": !showError })}>
      <Text color="red" fontSize="12">
        {meta.error}
      </Text>
    </div>
  );
}
