import { useMemo } from "react";
import DatePickerComponent from "react-datepicker";
import { Placement } from "popper.js";
import cx from "classnames";
import styles from "./DatePicker.module.css";
import closeIcon from "assets/images/11.svg";
import calendarIcon from "assets/images/calendar.png";

import cuid from "cuid";
import { dateFns } from "utilities/dateFns";

interface Props {
  value: string;
  onChange: (arg: Date | null) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  className?: string | undefined;
  disabled?: boolean;
  showTimeInput?: boolean;
  inProgress?: boolean;
  showYearDropdown?: boolean;
  showMonthDropdown?: boolean;
  placeholder?: string;
  label?: string | undefined;
  customDateFormat?: string | undefined;
  meta?: {
    touched?: boolean | undefined;
    error?: string | string[] | undefined | null;
  };
  look?: "common" | "editable";
  placement?: Placement;
  overrides?: { popup?: { className?: string }; container?: { className?: string } };
}

const months = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listopad",
  "Grudzień",
];
const days = ["Nie", "Pon", "Wt", "Śr", "Czw", "Pt", "Sob"];

const locale: Locale = {
  localize: {
    month: (n: any): any => months[n],
    day: (n: any): any => days[n],
  } as Locale["localize"],

  formatLong: {} as Locale["formatLong"],
};

export const DatePicker = ({
  onChange,
  value,
  className = "",
  disabled,
  onBlur,
  onFocus,
  customDateFormat,
  showTimeInput = true,
  overrides = {},
  placeholder,
  showYearDropdown,
  showMonthDropdown,
}: Props) => {
  const customId = useMemo(cuid, []);
  const selectedDate = value ? new Date(value) : null;

  return (
    <>
      <div className={cx(styles.container, overrides.container?.className || "")}>
        <DatePickerComponent
          showPopperArrow={false}
          showTimeInput={showTimeInput}
          timeInputLabel="Wybierz godzinę"
          locale={locale}
          maxDate={new Date()}
          yearDropdownItemNumber={100}    // enable scrolling
          scrollableYearDropdown={true}    // enable scrolling
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          autoComplete="off"
          popperClassName={cx(styles.popper, overrides.popup?.className || "")}
          onKeyDown={(e) => e.preventDefault()} // - disable input
          disabled={disabled}
          className={cx(className, styles.dateInput)}
          id={customId}
          dateFormat={customDateFormat || "dd/MM/yyyy HH:mm"}
          selected={selectedDate}
          wrapperClassName={styles.dateInputWrapper}
          placeholderText={placeholder || "Wybierz datę"}
          showWeekNumbers
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={
            selectedDate
              ? dateFns.format(selectedDate, customDateFormat || "dd/MM/yyyy HH:mm")
              : undefined
          }
        />
        {value ? (
          <img
            src={closeIcon}
            alt="wyczyść"
            className={styles.closeIcon}
            onClick={() => onChange(null)}
          />
        ) : (
          <label htmlFor={customId}>
            <img
              src={calendarIcon}
              alt="kalendarz"
              className={styles.closeIcon}
              onClick={() => onChange(null)}
            />
          </label>
        )}
        {/* <Spinner
          on={inProgress}
          color={{ dark: "blue", light: "white" }["dark"] as "blue" | "white"}
          style={spinnerStyle}
        /> */}
      </div>
    </>
  );
};
