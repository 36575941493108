import { useField } from "formik";
import { DetailedHTMLProps, InputHTMLAttributes, ReactNode } from "react";
import { Checkbox } from "../checkbox/Checkbox";
import { FieldError } from "./FieldError";

type Props<V> = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  name: keyof V;
  text: ReactNode;
  onChange?: (val: boolean) => void;
};

export function FieldCheckbox<V extends Record<string, any>>({ name, text, onChange }: Props<V>) {
  const _name = name as string;
  const [inputProps, , helpers] = useField(_name);
  if (inputProps.value === undefined) return null;
  return (
    <div className="mb-3">
      <Checkbox
        checked={inputProps.value}
        label={text}
        onChange={(e) => {
          helpers.setValue(e.target.checked);
          onChange?.(e.target.checked);
        }}
        name={name}
      />
      <FieldError<V> name={name} />
    </div>
  );
}
