import { DialogOverlay, DialogContent } from "@reach/dialog";
import Media from "react-media";
import cx from "classnames";
import styles from "./Modal.module.css";

interface Props {
  children: JSX.Element;
  controller: { isOpen: boolean; close: () => void };
  showCloseBtn?: boolean;
  size?:
    | {
        all?: { width: string };
        xlg?: { width: string };
        lg?: { width: string };
        md?: { width: string };
        sm?: { width: string };
      }
    | "auto"
    | "fullScreen";
}

function getWidth(
  matches: { xlg: boolean; lg: boolean; md: boolean; sm: boolean },
  size: Props["size"],
) {
  if (size === "auto") return "auto";
  if (size === "fullScreen") return undefined;
  if (size) {
    if (matches.xlg) return size.xlg?.width || size.all?.width || "50vw";
    if (matches.lg) return size.lg?.width || size.all?.width || "60vw";
    if (matches.md) return size.md?.width || size.all?.width || "70vw";
    if (matches.sm) return size.sm?.width || size.all?.width || "80vw";
  }
  return "50vw";
}

const mediaQueries = {
  sm: "(max-width: 599px)",
  md: "(min-width: 600px) and (max-width: 1199px)",
  lg: "(min-width: 1200px) and (max-width: 1399px)",
  xlg: "(min-width: 1400px)",
};

export function Modal({ controller, children, size, showCloseBtn = true }: Props) {
  return (
    <div>
      {controller.isOpen && (
        <DialogOverlay
          onDismiss={controller.close}
          style={{
            zIndex: 21, // incremented from 6 because Autocomplete item was above,
            display: size === "auto" ? "flex" : undefined,
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <Media queries={mediaQueries}>
            {(matches) => (
              <DialogContent
                aria-label="modal"
                className={cx({ [styles.fullScreen]: size === "fullScreen" })}
                style={{
                  width: getWidth(matches, size),
                  ...(size === "auto" ? { margin: "4vh auto 0" } : {}),
                  border: "1px solid rgba(0, 0, 0, 0.5)",
                  boxShadow: "0 2px 4px 3px rgba(0, 0, 0, 0.2), 0 8px 8px 5px rgba(0, 0, 0, 0.1)",
                  borderRadius: 5,
                  position: "relative",
                }}
              >
                <button
                  type="button"
                  className={cx(styles.closeBtn, "btn")}
                  onClick={controller.close}
                  style={{ display: showCloseBtn ? "block" : "none" }}
                >
                  <img src={""} alt="zamknij" />
                </button>
                {children}
              </DialogContent>
            )}
          </Media>
        </DialogOverlay>
      )}
    </div>
  );
}
