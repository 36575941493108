import { DOMAIN } from "../../constants";
import { useEffect, useRef } from "react";

interface Props {
  width: string;
  height: string;
  prefix: string;
}

export const KronaFrame = ({ width, height, prefix }: Props) => {
  const iframe = useRef<HTMLIFrameElement | null>(null);
  const currentToken = localStorage.getItem("token");

  useEffect(() => {
    iframe.current?.addEventListener("load", (e: any) => {
      if (e.target) {
        const innerDocument = e.target.contentDocument;
        if (innerDocument) {
          innerDocument.body.style.setProperty("background-color", "#f4f4f4");
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <iframe
      ref={iframe}
      width={width}
      height={height}
      src={DOMAIN + `reports/krona-report/${prefix}?authorization=Bearer ${currentToken}`}
      title="krona"
    />
  );
};
