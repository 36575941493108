export function getAnyErrorKey(
  error: { [key: string]: string } | Record<string, any>,
  key: string = "",
  fallback?: string,
) {
  const firstKey = Object.keys(error)[0];
  return (
    error[key] ||
    error.message ||
    error.detail ||
    error.details ||
    fallback ||
    error[firstKey] ||
    "Wystąpił błąd"
  );
}
