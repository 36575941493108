import produce from "immer";
import { StateCreator } from "zustand";

export function immerMiddleware<T extends Record<string | number | symbol, unknown>>(
  config: StateCreator<T, (fn: ((state: T) => void) | Partial<T>) => void>,
): StateCreator<T> {
  return (set, get, api) =>
    config(
      (fn) => (typeof fn === "function" ? set(produce(fn) as (state: T) => T) : set(fn as any)),
      get,
      api,
    );
}
