import qs from "query-string";

export type ParsedQuery = qs.ParsedQuery;

interface Options {
  allowEmpty?: boolean;
  forceFirstSign?: string;
}

interface QueryString {
  parse: (link: string) => { [key: string]: string };
  stringify: (
    query: { [key: string]: string | boolean | number | number[] },
    options?: Options,
  ) => string;
  merge: (arg: string[]) => string;
}

export const queryString: QueryString = {
  parse: function parseQuery(link: string = "") {
    if (!link || link.length === 0 || link.indexOf("?") === -1) {
      return {};
    }
    const qstr = link.split("?")[1];
    const query: { [key: string]: string } = {};
    const a = qstr.split("&");
    for (var i = 0; i < a.length; i++) {
      var b = a[i].split("=");
      query[decodeURIComponent(b[0])] = decodeURIComponent(b[1] || "");
    }
    return query;
  },
  stringify: function stringifyQuery(query, options = {}) {
    const { allowEmpty = false, forceFirstSign }: Options = options;
    let queryString = "";

    Object.keys(query).forEach((key) => {
      const value = query[key];
      let param = "";
      if (queryString.length === 0) {
        param += "?";
      } else {
        param += "&";
      }
      param += key + "=";
      if (Array.isArray(value)) {
        param += value;
      } else {
        param += encodeURIComponent(value);
      }
      if ((value !== undefined && value !== null && value !== "") || allowEmpty === true) {
        queryString += param;
      }
    });

    if (queryString.length > 0 && forceFirstSign) {
      queryString = forceFirstSign + queryString.substring(1);
    }

    return queryString;
  },
  merge: function mergeQueryStrings(queryStrings) {
    let query = {};
    queryStrings.forEach((string) => {
      query = { ...query, ...queryString.parse(string) };
    });
    return queryString.stringify(query);
  },
};

export function getQueryParam(name: string) {
  return queryString.parse(window.location.search)[name] || "";
}
