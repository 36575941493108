import { getUserInfo } from "api/users/calls";
import { ViewContainer } from "components/common/viewContainer/ViewContainer";
import { ActivateUser } from "pages/activateUser/ActivateUser";
import { Register } from "pages/register/Register";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, RouteProps, Switch } from "react-router-dom";
import { MainRouteRoot } from "routeRoots/Main";
import { ConfirmModalController } from "./components/utils/confirmModal/ConfirmModalController";
import { QueryProvider } from "./components/utils/queryProvider/QueryProvider";
import { QueryClient, QueryClientProvider } from "react-query";

import { ToastrController, useToastr } from "./components/utils/toastr/ToastrController";
import { Login } from "./pages/login/Login";
import { useAuthStore } from "./stores/auth";
import { useHydrationStore } from "./stores/hydration";
import { PasswordReset } from "pages/passwordReset/PasswordReset";
import { PasswordConfirm } from "pages/passwordConfirm/PasswordConfirm";
import { getAnyErrorKey } from "utilities/getAnyErrorKey";
import { BackendErrorDict } from "api/connector";

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
});

const Loader = () => (
  <div
    style={{ height: "100vh" }}
    className="w-100 d-flex align-items-center justify-content-center"
  >
    ładowanie...
  </div>
);

function AuthRoute(props: RouteProps) {
  const isAuthenticated = useAuthStore(
    (store) => store.isAuthenticated,
    () => false,
  );

  const isRehydrated = useHydrationStore((store) => store.authRehydrated);

  if (!isRehydrated) {
    return <Loader />;
  }
  if (!isAuthenticated) {
    return <Login />;
  }
  return <Route {...props} />;
}

export function logout() {
  useAuthStore.getState().actions.clear();
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
}

export function App() {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <QueryProvider>
          <ToastrController>
            <ConfirmModalController>
              <ViewContainer>
                <Switch>
                  <Route exact={true} path="/login" component={Login} />
                  <Route exact={true} path="/register" component={Register} />
                  <Route exact={true} path="/user-activate/:uid/:token" component={ActivateUser} />
                  <Route exact={true} path="/password/reset" component={PasswordReset} />
                  <Route
                    exact={true}
                    path="/password/reset/:uid/:token"
                    component={PasswordConfirm}
                  />
                  <AuthRoute path="/" component={AuthRoot} />
                </Switch>
              </ViewContainer>
            </ConfirmModalController>
          </ToastrController>
        </QueryProvider>
      </QueryClientProvider>
    </Router>
  );
}

const AuthRoot = () => {
  const saveUser = useAuthStore((store) => store.actions.saveUser);
  const me = useAuthStore((store) => store.user);
  const isAuthenticated = useAuthStore((store) => store.isAuthenticated);
  const toastr = useToastr();
  const isRehydrated = useHydrationStore((store) => Object.values(store).every(Boolean));

  useEffect(() => {
    async function getUser() {
      try {
        const user = await getUserInfo();
        saveUser(user);
      } catch (err) {
        toastr.warning({
          title: "Oj, coś nie tak...",
          text: getAnyErrorKey(err as BackendErrorDict),
        });
        logout();
      }
    }
    if (isAuthenticated) {
      getUser();
    }
  }, [saveUser, toastr, isAuthenticated]);

  if (!isRehydrated || !me) return <Loader />;

  return <MainRouteRoot />;
};
