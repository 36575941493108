import { queryFetch } from "api/connector";
import { ObtainTokenData, PostUser, User } from "./models";

export const obtainToken = (data: ObtainTokenData) =>
  queryFetch<{ access: string; refresh: string; id: number }>({
    method: "POST",
    url: "/users/token/obtain",
    data,
    tokenMiddleware: false,
  });

export const postUser = (data: PostUser) =>
  queryFetch<{ access: string; refresh: string; id: number }>({
    method: "POST",
    url: "/users/register",
    data,
    tokenMiddleware: false,
  });

export const postPasswordReset = (data: { email: string }) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/users/password/reset",
    data,
    tokenMiddleware: false,
  });

export const postPasswordResetConfirm = (data: {
  code: string;
  newPassword: string;
  uid: string;
  token: string;
}) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/users/password/reset/confirm",
    data,
    tokenMiddleware: false,
  });

export const activateUser = (data: { uid: string; token: string }) =>
  queryFetch<{ access: string; refresh: string }>({
    method: "POST",
    url: "/users/activate",
    data,
    tokenMiddleware: false,
  });

export const getUserInfo = () =>
  queryFetch<User>({
    method: "GET",
    url: "/users/userinfo",
  });
