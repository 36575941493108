import createStore from "zustand";
import { immerMiddleware } from "./middlewares";

export const useHydrationStore = createStore<{
  authRehydrated: boolean;
}>(
  immerMiddleware((_set) => ({
    authRehydrated: false,
  })),
);
