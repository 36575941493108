import { queryFetch } from "api/connector";
import { Poll, PostPoll, PollResults } from "./models";

export const postPollResults = (data: PostPoll) =>
  queryFetch<PollResults>({
    method: "POST",
    url: "/poll-results/items",
    data,
  });

export const getPollResults = (id: number) =>
  queryFetch<PollResults>({
    method: "GET",
    url: "/poll-results/items/" + id,
  });

export const patchPollResults = (data: Partial<PostPoll>, id: number) =>
  queryFetch<PollResults>({
    method: "PATCH",
    url: "/poll-results/items/" + id,
    data,
  });

export const getPoll = (uuid: string) =>
  queryFetch<Poll>({
    method: "GET",
    url: "/polls/items/" + uuid,
  });
