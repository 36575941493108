import { BackendErrorDict } from "api/connector";
import { useRef } from "react";
import { QueryObserverOptions, useQuery, UseQueryResult } from "react-query";
import { queryString } from "utilities/queryString";
import { Assign } from "utility-types";

interface BackendPagination {
  next?: string | null;
  previous?: string | null;
  count?: number | null;
  limit?: number | null;
  pageSize?: number;
}

interface Pagination {
  next: number | null;
  prev: number | null;
  count: number | null;
  page: number | null;
  limit: number | null;
  pageSize: number | null;
}
/**
 * preparing django pagination object
 */
export function getPagination(pagination: BackendPagination | null): Pagination {
  if (pagination === null) {
    return {
      next: null,
      prev: null,
      count: null,
      limit: null,
      page: null,
      pageSize: null,
    };
  }

  const { next, previous, count = null, limit = null, pageSize: backendPageSize } = pagination;

  function getPageSize() {
    return count && limit ? Math.ceil(count / limit / 10) * 10 : null;
  }

  const nextPage = next ? parseInt(queryString.parse(next).page, 10) : null;
  const prevPage = previous ? parseInt(queryString.parse(previous).page, 10) || 1 : null;
  const page = (function () {
    if (nextPage) return nextPage - 1;
    if (prevPage) return prevPage + 1;
    return 1;
  })();
  const pageSize = backendPageSize || getPageSize();
  let indexes = [];

  if (pageSize) {
    let i = 0;
    for (i; i < pageSize; i += 1) {
      indexes.push(pageSize * (page - 1) + i + 1);
    }
  }

  return {
    next: nextPage,
    prev: prevPage,
    count,
    page,
    limit,
    pageSize,
  };
}

export type ErrorType = { [key: string]: any };
export type SearchParams = { [key: string]: any };
type F1<T> = (search: SearchParams) => Promise<T>;
type F2<T> = (search: string) => Promise<T>;

export const createPaginatedQuery =
  <TRes extends unknown>(func: F1<TRes> | F2<TRes>) =>
  (
    key: any[],
    search: any,
    options?: QueryObserverOptions<TRes[], BackendErrorDict>,
  ): Assign<
    UseQueryResult,
    {
      data: TRes[];
      error: ErrorType | null;
      pagination: Pagination;
    }
  > => {
    const paginatedResponse = useRef<any>({
      next: null,
      prev: null,
      count: null,
      limit: null,
      page: null,
      pageSize: null,
      indexes: [],
    });
    const error = useRef<Error | null>(null);
    const res = useQuery<any, any>(key, () => func(search), {
      keepPreviousData: true,
      select: (data) => {
        if (data) {
          paginatedResponse.current = data;
          return data.results;
        }
      },
      onError: (err) => {
        error.current = err;
      },
      ...options,
    });

    if (res.isError) {
      return {
        ...res,
        error: error.current,
        pagination: paginatedResponse.current,
        data: [],
      };
    } else {
      const pagination = getPagination(paginatedResponse.current);
      return {
        ...res,
        error: null,
        data: res.data ? res.data : [],
        pagination,
      };
    }
  };
