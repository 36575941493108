import { queryFetch } from "api/connector";
import axios, { AxiosError } from "axios";
import { dateFns } from "utilities/dateFns";
import { DOMAIN } from "../../constants";
import { Report, TableData } from "./models";

export const getReport = (id: string) =>
  queryFetch<Report>({
    method: "GET",
    url: `/reports/single-report/${id}`,
  });

// export const getTable = (id: string) =>
//   queryFetch<TableData>({
//     method: "GET",
//     url: `/reports/table-report/${id}`,
//   });

export const getTable = (id: string) =>
  queryFetch<TableData>({
       method: "GET",
       url: `/reports/table-report/${id}`,
  });

export const validateReport = (data: { birthday: string }, code: string) =>
  queryFetch<{ isVerified: boolean }>({
    method: "POST",
    url: `/reports/verify-report/${code}`,
    data,
  });

export const getKrona = async (prefix: string) =>
  await axios(
    DOMAIN +
      `static/${prefix}.centrifuge.mh15k10000newdbnewcf.f_Bacteria.classification_krakn.krona_level_N-1.html`,
    {
      method: "GET",
    },
  )
    .then((el) => el.data)
    .catch((err: AxiosError) => {
      throw err.response?.data;
    });

export const fileFactory = (() => {
  function getDate() {
    return dateFns.format(new Date(), "dd-MM-yyyy-HH-mm");
  }
  return {
    reportPdf: (code: string) => ({
      url: `/reports/pdf-report/${code}`,
      name: `raport-${getDate()}`,
    }),
    simpleReportPdf: (code: string) => ({
      url: `/reports/simple-pdf-report/${code}`,
      name: `raport-${getDate()}`,
    }),
  };
})();


export const getMinMax = ( x: Number,  y: Number,  z: Number) => {
  return(x > y ? (x < z ? x : z ): y);
};