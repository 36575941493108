import { useRef, useEffect } from "react";
import cuid from "cuid";
import { RECAPTCHA_KEY } from "../../../constants";

const siteKey = RECAPTCHA_KEY

interface Props {
  callback: (token: string) => void;
}

export const Captcha = ({ callback }: Props) => {
  const id = useRef(cuid());
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      // @ts-ignore-next-line
      window.grecaptcha.render(id.current, {
        sitekey: siteKey,
        callback,
      });
    }
  }, [callback]);

  useEffect(() => {
    initialRender.current = false;
  }, []);

  return <div id={id.current} />;
};
