import React, {
  createContext,
  useContext,
  memo,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Toastr } from "./Toastr";
import cuid from "cuid";
import styles from "./Toastr.module.css";
import { useEffect } from "react";

interface Props {
  children: React.ReactNode;
}

export interface ToastSubscription {
  id: string;
  title: string;
  text: string | React.ReactElement;
  type: "success" | "failure" | "neutral" | "warning";
}
export type NewToastrSubscription = Omit<ToastSubscription, "id">;

// export interface Toastr {
//   open: (arg: NewToastrSubscription) => () => void;
// }

export const context = createContext<{ open: (arg: NewToastrSubscription) => () => void }>({
  open: (arg) => () => {},
});

export const ToastrController = memo(({ children }: Props) => {
  const [subscriptions, setSubscriptions] = useState<Record<string, ToastSubscription>>({});
  const timeouts = useRef<Record<string, NodeJS.Timeout>>({});
  const unsubscribe = useCallback(
    (id: string) =>
      setSubscriptions((s) => {
        const newState = { ...s };
        delete newState[id];
        return newState;
      }),
    [],
  );

  const subscribe = useCallback(
    (subscription: NewToastrSubscription) => {
      const id = cuid();
      const _unsubscribe = () => {
        unsubscribe(id);
      };
      timeouts.current[id] = setTimeout(_unsubscribe, 10000);
      setSubscriptions((s) => {
        return {
          ...s,
          [id]: { ...subscription, id },
        };
      });
      return _unsubscribe;
    },
    [unsubscribe],
  );

  const clearHideTimeout = useCallback((id: string) => {
    clearTimeout(timeouts.current[id]);
  }, []);

  const lazyUnsubscribe = useCallback(
    (id: string) => {
      clearTimeout(timeouts.current[id]);
      timeouts.current[id] = setTimeout(() => {
        unsubscribe(id);
      }, 2000);
    },
    [unsubscribe],
  );

  useEffect(() => {
    const handler = ((e: CustomEvent<NewToastrSubscription>) => {
      subscribe(e.detail);
    }) as EventListener;
    document.addEventListener("toastr", handler);
    return () => {
      document.removeEventListener("toastr", handler);
    };
  }, [subscribe]);

  const contextValue = useMemo(() => ({ open: subscribe }), [subscribe]);
  return (
    <>
      <context.Provider value={contextValue}>{children}</context.Provider>
      <div className={styles.container}>
        {Object.values(subscriptions).map((subscription) => (
          <Toastr
            className="mb-2"
            key={subscription.id}
            type={subscription.type}
            title={subscription.title}
            text={subscription.text}
            clearHideTimeout={() => clearHideTimeout(subscription.id)}
            lazyUnsubscribe={() => lazyUnsubscribe(subscription.id)}
          />
        ))}
      </div>
    </>
  );
});

export const useToastr = () => {
  const toastr = useContext(context);
  return useMemo(
    () => ({
      success: ({ text, title }: Omit<ToastSubscription, "id" | "type">) => {
        return toastr.open({ text, title, type: "success" });
      },
      failure: ({ text, title }: Omit<ToastSubscription, "id" | "type">) => {
        return toastr.open({ text, title, type: "failure" });
      },
      neutral: ({ text, title }: Omit<ToastSubscription, "id" | "type">) => {
        return toastr.open({ text, title, type: "neutral" });
      },
      warning: ({ text, title }: Omit<ToastSubscription, "id" | "type">) => {
        return toastr.open({ text, title, type: "warning" });
      },
    }),
    [toastr],
  );
};
