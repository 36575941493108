import {
  format,
  subMonths,
  startOfQuarter,
  subQuarters,
  lastDayOfQuarter,
  lastDayOfMonth,
  startOfMonth,
  startOfWeek,
  eachWeekOfInterval,
  lastDayOfWeek,
  add,
  differenceInDays,
  subDays,
  addDays,
  subMinutes,
  addMinutes,
  isYesterday,
  isToday,
} from "date-fns";

import plLocale from "date-fns/locale/pl";

export const dateFns = (function () {
  const dateFnsLocales = {
    pl: plLocale,
    en: undefined,
  };
  return {
    format(date: Date, form: string, params = {}) {
      const locale = dateFnsLocales["pl"];
      return format(date, form, { locale, ...params });
    },
    formatRelative(date: Date, options?: any) {
      if (isToday(date)) return `dzisiaj o ${format(date, "HH.mm")}`;
      if (isYesterday(date)) return `wczoraj o ${format(date, "HH.mm")}`;
      return format(date, "dd.MM.yyyy");
    },
    add,
    addDays,
    subMinutes,
    addMinutes,
    subDays,
    subMonths,
    subQuarters,
    startOfMonth,
    lastDayOfMonth,
    startOfWeek,
    lastDayOfWeek,
    startOfQuarter,
    lastDayOfQuarter,
    differenceInDays,
    eachWeekOfInterval,
  };
})();
