import { useField } from "formik";
import { ComponentProps } from "react";
import { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { cx } from "../../../utilities/cx";
import { DatePicker } from "../datePicker/DatePicker";
import { Text } from "../text/Text";
import { FieldError } from "./FieldError";

type Props<V> = DetailedHTMLProps<InputHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  name: keyof V;
  label?: string;
  format?: string;
  showTimeInput?: boolean;
  placement?: ComponentProps<typeof DatePicker>["placement"];
  showYearDropdown?: boolean;
  showMonthDropdown?: boolean;
};

export function FieldDate<V extends Record<string, any>>({
  name,
  label,
  format = "yyyy-MM-dd HH:mm",
  showTimeInput,
  className,
  placeholder,
  showYearDropdown,
  showMonthDropdown,
}: Props<V>) {
  const _name = name as string;
  const [inputProps, , helpers] = useField(_name);
  if (inputProps.value === undefined) return null;
  return (
    <div className={cx("mb-3", className)}>
      {label && (
        <Text color="white" fontSize="12" as="label" htmlFor={_name} className="mb-2">
          {label}
        </Text>
      )}
      <DatePicker
        value={inputProps.value}
        showTimeInput={showTimeInput}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        placeholder={placeholder}
        customDateFormat={format}
        onChange={(date) => {
          if (!date) {
            helpers.setValue(null);
          } else {
            helpers.setValue(date);
          }
        }}
      />
      <FieldError<V> name={name} />
    </div>
  );
}
