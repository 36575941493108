import styles from "./Text.module.css";
import cx from "classnames";
import { ForwardedRef, forwardRef } from "react";
import { COLORS } from "../../../constants";
import { ElementProps, CustomNodePropsCreator } from "../../../utilities/typeUtilities";

interface BtnProps extends ElementProps {
  fontSize?: `${number}` | `${number}rem`;
  fontWeight?: "500" | "700" | "900";
  color?: keyof typeof COLORS | "white";
  italic?: boolean;
}
type Txt = CustomNodePropsCreator<BtnProps, "span">;

export const TextInner = (
  { as, children, fontSize, color, fontWeight, italic = false, className, ...rest }: BtnProps,
  ref: ForwardedRef<React.ElementType | undefined>,
) => {
  const Tag = as || "span";
  const classNames = cx(className, styles[`fs-${fontSize}`], styles[`fw-${fontWeight}`], {
    [styles.italic]: italic,
  });
  return (
    <Tag {...rest} ref={ref} className={classNames} style={{ color: color }}>
      {children}
    </Tag>
  );
};

export const Text = forwardRef(TextInner) as Txt;
