import { Dashboard } from "pages/dashboard/Dashboard";
import { Krona } from "pages/krona/Krona";
import { Poll } from "pages/poll/Poll";
import { Reports } from "pages/reports/Reports";
import { Table } from "pages/table/Table";
import { Route, Switch } from "react-router-dom";

export const MainRouteRoot = () => {
  return (
    <Switch>
      <Route exact={true} path="/" component={Dashboard} />
      <Route exact={true} path="/poll/:id/:uuid" component={Poll} />
      <Route exact={true} path="/reports/:code" component={Reports} />
      <Route exact={true} path="/krona/:code" component={Krona} />
      <Route exact={true} path="/table/:code" component={Table} />

      <Route
        render={() => (
          <h1 className="d-flex align-items-center justify-content-center font-bold">404</h1>
        )}
      />
    </Switch>
  );
};
