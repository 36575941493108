import sygnet from "assets/images/sygnetNB.png";
import { Field } from "components/common/form/Field";
import { Formik, FormikHelpers } from "formik";
import { Link, RouteComponentProps } from "react-router-dom";
import * as yup from "yup";
import { BackendErrorDict } from "api/connector";
import { FieldError } from "components/common/form/FieldError";
import { Text } from "components/common/text/Text";
import { postPasswordResetConfirm } from "api/users/calls";
import { useState } from "react";

type Values = {
  code: string;
  newPassword: string;
};

const validationSchema: yup.SchemaOf<Values> = yup.object({
  code: yup.string().matches(/^[^@]+$/, "Kod nie powinien zawierać znaku @")
    .required("Wpisz kod otrzymany w wiadomości"),
  newPassword: yup
    .string()
    .min(8, "Hasło powinno mieć co najmniej 8 znaków")
    .required("Wpisz hasło"),
});

export const PasswordConfirm = ({ match }: RouteComponentProps<{ uid: string; token: string }>) => {
  const { uid, token } = match.params;
  const [isConfirmed, setIsConfirmed] = useState(false);

  async function submit(values: Values, helpers: FormikHelpers<Values>) {
    try {
      await postPasswordResetConfirm({ ...values, uid, token });
      setIsConfirmed(true);
    } catch (error) {
      helpers.setErrors(error as BackendErrorDict);
    }
  }

  const initialValues: Values = { code: "", newPassword: "" };

  return (
    <Formik initialValues={initialValues} onSubmit={submit} validationSchema={validationSchema}>
      {({ isSubmitting, handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="d-flex align-items-center justify-content-center h-100">
              <div className="w-50">
                <div className="d-flex align-items-start pb-5">
                  <img className="sygnetSmall" src={sygnet} alt="" />
                  <Text fontSize="1.65rem">Odzyskiwanie konta</Text>
                </div>
                {isConfirmed ? (
                  <div className="row d-flex flex-wrap align-items-center">
                    <div className="col-12">
                      <h3>Gotowe</h3>
                      <h5>Nowe hasło zostało utworzone pomyślnie. Teraz możesz się zalogować.</h5>
                      <div className="mt-4">
                        <Link to="/login" className="btnSecondary px-2" type="submit">
                          Zaloguj się
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row d-flex">
                    <div className="col-12 col-md-6 pe-4">
                      <div className="pb-2">
                        <Field<Values>
                          name="code"
                          placeholder="Wpisz kod otrzymany w wiadomości"
                          inputClassName="input"
                          label="Wpisz kod otrzymany w wiadomości"
                        />
                        <Field<Values>
                          name="newPassword"
                          placeholder="Wpisz nowe hasło"
                          type="password"
                          inputClassName="input"
                          label="Wpisz nowe hasło"
                        />
                        <FieldError name="detail" />
                      </div>
                      <div>
                        <button className="btnPrimary px-2" type="submit" disabled={isSubmitting}>
                          Kontynuuj{isSubmitting && "..."}
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <h5 className="mt-2">Nie masz konta?</h5>
                      <div className="mt-3">
                        <Link to="/register" className="btnSecondary px-2" type="submit">
                          Zarejestruj się
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
