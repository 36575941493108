// import { DOMAIN } from "../../constants";
import { /*useEffect,*/ useRef } from "react";

// import { useMemo } from "react";
import React from "react";
import { cx } from "utilities/cx";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Popover from '@material-ui/core/Popover';

import SearchBar from "material-ui-search-bar";


// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import { withStyles, Theme, createStyles } from '@material-ui/core/styles';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// import ExpandIcon from '../../assets/images/expand.svg';
// import CollapseIcon from '../../assets/images/collapse.svg';
// import SubrowImg from '../../assets/images/subrow.svg';
import markerImg from "../../assets/images/marker.svg";
import explainImg from "../../assets/images/table_explain.jpg";
import lineImg from "../../assets/images/line.svg";

import ExpandIcon from '@material-ui/icons/ExpandMore';
import CollapseIcon from '@material-ui/icons/ExpandLess';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import InfoIcon from '@material-ui/icons/Info';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';


import styles from "../reports/Reports.module.css";
import tstyles from "./Table.module.css";
// import { current } from "immer";

import { TableItem, TableData } from "api/reports/models";
import { htmlRender } from "utilities/htmlRender";

const referencePath = "reference_table/";

interface Props {
  children?: React.ReactNode;
  data: TableItem[];
  order: Order;
  orderBy: Key;
}

interface SubProps {
  children?: React.ReactNode;
  row: TableItem;
  order: Order;
  orderBy: Key;
}

interface InfoProps {
  row: TableItem;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#b1235c',     
    }, 
    secondary: {
      main: '#79357e'
    }, 
  }
});
    

function getComparator(
  order: Order,
  orderBy: Key,
): (a: TableItem, b: TableItem ) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type Order = 'asc' | 'desc';
type Key = 'value' | 'name';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}
// function a11yProps(index: number) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

const ReferenceImg = (props: {data: TableItem, small:boolean}) => {
    // const perc = props.pos*100
    if (props.data.range == null ) { return(<div>bakteria rzadka*</div>) } else {
      var perc = 0;
      if (props.data.value * 100 < props.data.range.start) {
        perc = 10; 
      } else if (props.data.value * 100 > props.data.range.end) {
        perc = 90;
      } else { 
        perc = 20 + 60*(props.data.value * 100 - props.data.range.start ) / (props.data.range.end - props.data.range.start) 
      }
    return(<div className={props.small ? tstyles.scaleBoxSmall : tstyles.scaleBox}>
              {/* <div className={cx(tstyles.scaleBoxBody )}></div> */}
              <img 
               className={cx(tstyles.scaleBoxBody )}                                
                    src={process.env.PUBLIC_URL + "/" + referencePath.concat(props.data.name).concat(".png") }
                    alt=""
              /> 
              <img className={cx(tstyles.scaleLine )}                                
                   src={lineImg}
                   alt=""
              /> 
              {/* <div className={cx(tstyles.scaleLine )}></div> */}
              <div style={{ right: "80%", bottom: "0%", position: "absolute", fontSize: "0.8em" }}>
                {props.data.range.start.toFixed(2)}
              </div>
              <div style={{ left: "80%", bottom: "0%", position: "absolute", fontSize: "0.8em" }}>
                {props.data.range.end.toFixed(2)}
              </div>
              <img className={tstyles.scaleBoxMarker}
                    style={{
                      left: `${ perc > 99 ? 99 : perc }%`,
                      marginLeft: props.small? "-7px": "-8px",
                    }}                                      
                    src={markerImg}
                    alt=""
              />
    </div>)}
}

function toPerc(value: number):string {
  return((100*value).toFixed(2).concat("%"))
}


const Info = (props: InfoProps ) => {
  const {  row , ...other } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const header = "<strong><i>"+row.name+"</i></strong><br/>";
  const info = row.taxonInfo.join("<br/><br/>");

  return (<div {...other}>
              <IconButton
                    aria-label="expand info"
                    size="small"
                    disabled={!hasDescription(row.taxonInfo)}
                    className={tstyles.infoButton}
                    onClick={handleClick}
                    // onClick={() => displayInfo("<strong><i>"+row.name+"</i></strong>"+row.taxonInfo)}
              >
                  <InfoIcon style={{color: hasDescription(row.taxonInfo) ? '#b1235c' : 'disabled'}} />
                 {/* <div className={tstyles.tableHintIcon}><em>i</em></div>  */}
              </IconButton>
              
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={cx(tstyles.taxInfoDiv, {  })}>
          <div className={cx("d-flex justify-content-between")}>
            <div className={cx("mb-2", tstyles.taxInfoText)}>{htmlRender(header)}</div>
            <button className={cx(styles.closeBtn, styles.infoBoxBtn)} onClick={() => handleClose()}>x</button>
          </div>
          <div className={cx("mb-2", tstyles.taxInfoText)} >
            {htmlRender(info)}           
          </div>
        </div>                
      </Popover></div>)
}

const Row = (props: SubProps ) => {
  const { /*children,*/ row, order, orderBy } = props;

  const [open, setOpen] = React.useState(false);
  console.log({row});

  return (
    <React.Fragment>
    {row && <TableRow  style={{ borderTopColor: "#b1235c", borderTopWidth: "medium" }}>
        <TableCell style={{margin: "-5px", borderTopColor: "#b1235c", borderTopWidth: "thin" }} >
          {row.contains.length !== 0 ?
           <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {/* {open ? <img src={CollapseIcon}/> : <img src={ExpandIcon}/>} */}
            {open ? <CollapseIcon color='primary'/> : <ExpandIcon  color='primary'/>}
          </IconButton>  :
           <IconButton
            // aria-label="expand row"
            size="small"
            disabled={true}
          >
           {/* <img src={CollapseIcon}/> */}
           <ArrowForwardIcon  color='primary'/>
          </IconButton>}  
        </TableCell>
        
        <TableCell component="th" scope="row"  style={{ borderTopColor: "#b1235c", borderTopWidth: "thin" }}>
        <div className={cx(tstyles.nameTag)}>{row.name}
          {hasDescription(row.taxonInfo) && <Info row={row}/>}
        </div>
        </TableCell>
        <TableCell align="right"  style={{ borderTopColor: "#b1235c", borderTopWidth: "thin" }}>
          {toPerc(row.value)}
        </TableCell>
        
        <TableCell align="center"  style={{ borderTopColor: "#b1235c", borderTopWidth: "thin" }}>
          <ReferenceImg data={row} small={false}/>
          {/* <ReferenceImg pos={row.value} small={false}/> */}
        </TableCell> 
      </TableRow>}
      {row && row.contains.length !== 0 && open && 
      <TableRow>
        {/* <TableCell/> */}
        <TableCell style={{ padding: "3px" }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" style={{  paddingLeft: 20, paddingRight: 8 }} >
                <TableHead>
                </TableHead>
                <TableBody>
                  {stableSort(row.contains, getComparator(order, orderBy)).map((subcontent) => (
                  // {row.contains.map((subcontent) => (
                    <TableRow>
                      <TableCell  component="th" scope="row">
                        {/* <div ><img src={SubrowImg}/> */}
                        <div className={cx(tstyles.nameTag)}>
                          <SubdirectoryArrowRightIcon color='primary' style={{marginRight: '5px', marginLeft: '60px'}}/>
                          {subcontent.name}
                          {hasDescription(subcontent.taxonInfo) && <Info row={subcontent}/>}
                        </div>
                      </TableCell>
                      <TableCell   align="right">
                        {toPerc(subcontent.value)} ({toPerc( subcontent.value / row.value )}) 
                      </TableCell>
                      <TableCell  align="center" width="50%">
                        <ReferenceImg data={subcontent} small={true}/>                      
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell> 
      </TableRow>
    }
    </React.Fragment>)
} 

const TaxonTab = ({data, order, orderBy}: Props) =>  {
  const [filteredData, setFilteredData] = React.useState<TableItem[]>(data);
  const [searched, setSearched] = React.useState<string>("");

  const requestSearch = (searchedVal: string) => {
    const filteredRows = data.filter((row) => {
      // return row.name.toLowerCase().includes(searchedVal.toLowerCase())
      return row.name.toLowerCase().includes(searchedVal.toLowerCase()) 
    });
    setFilteredData(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  return (
    <div
      style={{
        width: "100%",
        minHeight: "50px"
      }}
    > 
      
    <TableContainer component={Box}>
        <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
          placeholder="Filtruj wg nazwy taksonu"
        />
      <Table aria-label="collapsible table" 
      style={{  paddingLeft: 8, paddingRight: 8, maxWidth: '900px', }}
      size="small" >
        <TableHead >
          <TableRow >
            <TableCell style={{ fontSize: "1rem", fontWeight: "600", lineHeight: '1rem', paddingBottom: 0, borderBottomWidth: 0}} width="5%"/>
            <TableCell style={{ fontSize: "1rem", fontWeight: "600", lineHeight: '1rem', paddingBottom: 0, borderBottomWidth: 0}} >Nazwa taksonu</TableCell>
            <TableCell style={{ fontSize: "1rem", fontWeight: "600", lineHeight: '1rem', paddingBottom: 0, borderBottomWidth: 0}} align="right" >Zawartość</TableCell>
            <TableCell style={{ fontSize: "1rem", fontWeight: "600", lineHeight: '1rem', paddingBottom: 0, borderBottomWidth: 0}} align="center" width="50%" >Zawartość względem </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{paddingTop: 0 }} width="5%"/>
            <TableCell style={{ fontSize: "1rem", fontWeight: "600", lineHeight: '1rem', paddingTop: 0 }}>bakterii</TableCell>
            <TableCell style={{ fontSize: "1rem", fontWeight: "600", lineHeight: '1rem', paddingTop: 0  }} align="right" >bakterii [%]</TableCell>
            <TableCell style={{ fontSize: "1rem", fontWeight: "600", lineHeight: '1rem', paddingTop: 0  }} align="center" width="50%" >grupy odniesienia</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
        {stableSort(filteredData, getComparator(order, orderBy))
                .map((el: TableItem) => {
                  return (
            <Row key={el.name} row={el} order={order} orderBy={orderBy}  />
          )})}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
};

/*function useContainerSize() {
  const ref = useRef<HTMLDivElement | null>(null);

  const refStyle = ref.current ? window.getComputedStyle(ref.current) : null;

  const size = useMemo(
    () =>
      ref.current && refStyle
        ? {
            width:
              ref.current.offsetWidth -
              parseFloat(refStyle.paddingLeft) -
              parseFloat(refStyle.paddingRight),
            height:
              ref.current.offsetHeight -
              parseFloat(refStyle.paddingTop) -
              parseFloat(refStyle.paddingBottom),
          }
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // console.log(ref, size);
  return [ref, size] as const;
}*/

export function hasDescription(description: string[]) {
  return(description.length > 0 && description[0].length > 0);
}

function flatten(items: TableItem[], depth: number):TableItem[] {
  if (depth === 0) return(items)
  var to_flat = items.map(function(i: TableItem) { return i.contains}, items)
  return(flatten(to_flat.flat(),  depth-1))
}

export var selectedInfo = "";
export var openInfo = false;
export var taxInfoList = {};


export const TableFrame = (
    { tableData }: { tableData: TableData}  ) => {

  // const [ref , size] = useContainerSize();
  const ref = useRef<HTMLDivElement | null>(null);
  // const currentToken = localStorage.getItem("token");

  const [value, setValue] = React.useState(0);
  const [sorting, setSorting] = React.useState('');
  const [order, setOrder] = React.useState('desc' as Order);
  const [orderBy, setOrderBy] = React.useState('value' as Key);
  const [openExplain, setOpenExplain] = React.useState(false);


  const handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSorting(event.target.value as string);
    if (event.target.value as string === 'az') {
      setOrder('asc' as Order);
      setOrderBy('name' as Key);
    } else {
      setOrder(event.target.value as Order);
      setOrderBy('value' as Key);
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
    <div
      style={{
        width: "100%",
        minHeight: "100px"
      }}
    > 
      <div className={cx("d-flex justify-content-between")}>
        <div>
          <div className={cx("d-flex")}><h5>Tabela składu mikrobiomu</h5>
              <IconButton
                    aria-label="expand table info"
                    size="small"
                    className={tstyles.infoButton}
                    onClick={() => setOpenExplain(!openExplain)}
              >
                  <InfoIcon style={{color: '#b1235c' }} />
              </IconButton>
          </div>
              
        <Box className="jss9 mx-2 px-2" sx={{ width: '100%', minWidth: "50px", display: 'inline-flex', justifyContent: 'center' }}>
        <div className={cx(tstyles.explainDiv, { "d-none": !openExplain })}>
          {/* <div className="d-flex justify-content-end" > */}
          <div>
            <div style={{float: 'right'}}><img style={{ width: '100%', }}                                 
                src={explainImg}
                alt=""
            />
          </div>
          <div style={{right: '10px', position: 'absolute'}} ><button className={cx(styles.closeBtn)} style={{color: '#b1235c'}} onClick={() => setOpenExplain(false)}>x</button></div>
          </div>
        </div>                
      </Box>
      </div>
      <div className={cx(" mx-2 mb-2 ")} style={{width: "200px"}}>      
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Sortuj</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={sorting}
            label="Sortuj tabelę:"
            autoWidth
            onChange={handleSortChange}
          >
            <MenuItem value='az'>A-Z</MenuItem>
            <MenuItem value='asc'>Udział procentowy rosnąco</MenuItem>
            <MenuItem value='desc'>Udział procentowy malejąco</MenuItem>
          </Select>
          </FormControl>
        </div> 
      </div>

    <div className={cx("d-flex justify-content-between", tstyles.boxDiv)} style={{ width: '100%'}} >
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value}
          textColor="primary"
          variant={ (ref?.current?.offsetWidth?? 800 < 600) ?  "scrollable": "fullWidth"}
          // variant="scrollable"
          scrollButtons="auto"
          indicatorColor="secondary"
          onChange={(event, newValue) => {
            setValue(newValue);
          }} 
          className={cx(tstyles.tabPanel)}
          title="Poziom taksonomiczny"
          aria-label="basic tabs example">
          {/* <Tab label="Typ" {...a11yProps(0)} />
          <Tab label="Rodzina" {...a11yProps(1)} />
          <Tab label="Rodzaj" {...a11yProps(2)} />
          <Tab label="Gatunek" {...a11yProps(3)} /> */}
          
          <Tab label="Typ" className={cx(tstyles.tabLabel)}/>
          <Tab label="Rodzina" className={cx(tstyles.tabLabel)} />
          <Tab label="Rodzaj"  className={cx(tstyles.tabLabel)}/>
          <Tab label="Gatunek" className={cx(tstyles.tabLabel)}/>
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <TaxonTab data={tableData.data} order={order} orderBy={orderBy} />
      </TabPanel>
      <TabPanel value={value} index={1}>
         <TaxonTab data={flatten(tableData.data, 1)}  order={order} orderBy={orderBy} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TaxonTab data={flatten(tableData.data, 2)} order={order} orderBy={orderBy} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TaxonTab data={flatten(tableData.data, 3)}  order={order} orderBy={orderBy} />
      </TabPanel>
    </Box>
    </div>  
  </div>
    </MuiThemeProvider>
    );
};

