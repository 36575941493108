import { useHistory, useParams } from "react-router-dom";
import "survey-react/survey.css";
import styles from "../reports/Reports.module.css";
import { cx } from "utilities/cx";
import { KronaFrame } from "./KronaFrame";
import { useMemo, useRef, useState } from "react";
import { PrintPdfButton } from "pages/reports/ReportPdf";
import { useReport } from "api/reports/hooks";
import { reportKeys } from "api/reports/keys";
import { getAnyErrorKey } from "utilities/getAnyErrorKey";
import { useToastr } from "components/utils/toastr/ToastrController";
import { fileFactory } from "api/reports/calls";
import { fileDownloader } from "fileDownloader";
import { useAuthStore } from "stores/auth";

function useContainerSize() {
  const ref = useRef<HTMLDivElement | null>(null);

  const refStyle = ref.current ? window.getComputedStyle(ref.current) : null;

  const size = useMemo(
    () =>
      ref.current && refStyle
        ? {
            width:
              ref.current.offsetWidth -
              parseFloat(refStyle.paddingLeft) -
              parseFloat(refStyle.paddingRight),
            height:
              ref.current.offsetHeight -
              parseFloat(refStyle.paddingTop) -
              parseFloat(refStyle.paddingBottom),
          }
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // console.log(ref, size);
  return [ref, size] as const;
}

export const Krona = () => {
  const history = useHistory();
  const [ref, size] = useContainerSize();
  const [open, setOpen] = useState(false);

  const { code } = useParams<{ code: string }>();
  const toastr = useToastr();
  const me = useAuthStore((store) => store.user);

  const { data: report, isLoading: inProgress } = useReport(reportKeys.report(code), String(code), {
    onError: (err) => {
      toastr.neutral({
        title: "",
        text: getAnyErrorKey(err),
      });
      history.goBack();
    },
  });

  if (inProgress) {
    return <div>Ładowanie raportu</div>;
  }

  if (!report) {
    return <div>Brak raportu</div>;
  }

  async function handleDownloadPDF(code: string) {
    const { url, name } = fileFactory.reportPdf(code);
    const response = await fileDownloader({
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
    } else {
      toastr.warning({
        title: "Oj, coś nie tak.",
        text: getAnyErrorKey(response.error),
      });
    }
  }

  return (
    <div className="d-flex align-items-start justify-content-center">
      <div className="mainContainer">
        <div className="row mt-5">
          <div className="d-flex align-items-end justify-content-end">
            <div className="pb-1 cursor-pointer" onClick={() => history.push("/")}>
              {"<"} Powrót do pulpitu
            </div>
          </div>
          <div className="col-12 col-md-3 mb-4 pe-0">
            <div className={cx("d-flex flex-column justify-content-between p-4", styles.navBox)}>
              <h2 className="pb-5 mb-5">Raport</h2>
              <nav className="mb-4">
                {report.data.map(({ page, title }) => (
                  <div
                    className={styles.navLink}
                    onClick={() => {
                      history.push(`/reports/${code}?page=${page}`);
                    }}
                  >
                    {title}
                  </div>
                ))}
              </nav>
              {report && report.isFromResearchStage ? (
                <div className=" d-flex flex-column align-items-center justify-content-center mt-4 pt-2 ">
                  <button
                    className="btnSecondary px-2 mb-2"
                    onClick={() => handleDownloadPDF(code)}
                  >
                    Pobierz raport
                  </button>
                </div>
              ) : (
                <div className="d-flex flex-column align-items-start mt-4 pt-2">
                  <button
                    className="btnSecondary px-2 mb-2"
                    onClick={() => history.push(`/reports/${code}`)}
                  >
                    Raport
                  </button>
                  <PrintPdfButton report={report} />
                </div>
              )}
            {report && report.hasTable && <button
                className="btnSecondary px-2"
                onClick={() => history.push("/table/" + code)}
              >
                Tabela składu mikrobiomu
              </button>}
            </div> 
          </div>
          <div className="col-12 col-md-9 ps-0">
            <div className={cx("px-4", styles.reportBox)} ref={ref}>
              <div className="content mb-4" style={{ overflow: "auto", maxWidth: "100vw" }}>
                <div className="col-12 position-relative">
                  <div className={styles.hintWrapper}>
                    <button className={styles.hint} onClick={() => setOpen(true)}>
                      <em>i</em>
                    </button>
                    <div className={cx(styles.hintText, { "d-none": !open })}>
                      <div className="d-flex justify-content-end">
                        <button className={styles.closeBtn} onClick={() => setOpen(false)}>
                          x
                        </button>
                      </div>
                      <p className="mb-2">
                        <strong>Interaktywna przeglądarka Twojego mikrobiomu</strong>
                        <br />
                        <br />
                        <strong>Czym jest przeglądarka i&nbsp;co można z&nbsp;niej odczytać?</strong>
                        <br />
                        Przeglądarka pomoże Ci poznać skład Twojej mikrobioty jelitowej. Dowiesz się
                        z&nbsp;niej, jakie konkretnie mikroorganizmy tworzą Twój mikrobiom i&nbsp;jak często
                        występują. Przeglądarka przedstawia procentowy udział wszystkich
                        zidentyfikowanych bakterii w&nbsp;Twojej próbce kału.  Wykres zbudowany jest z
                        okręgów, a&nbsp;każdy z&nbsp;nich reprezentuje kolejne poziomy klasyfikacji
                        biologicznej. Kierując się od środka wykresu na zewnątrz poznasz
                        odpowiednio: typ, klasę, rząd, rodzinę, rodzaj aż&nbsp;po gatunek danej bakterii.
                        <br />
                        <br />
                        <strong>Do czego służą poszczególne przyciski?</strong>
                        <br />
                        Ważną pozycją jest <strong> okienko wyszukiwarki</strong>, opisane hasłem
                        <strong>“wyszukaj”</strong>. Z&nbsp;jego pomocą możesz znaleźć interesujące Cię
                        mikroorganizmy. <br />
                        <br />
                        <strong>Strzałki</strong> znajdujące się na&nbsp;górze z&nbsp;lewej strony, obok pola
                        wyszukiwania, umożliwiają powrót do wcześniejszych wyświetleń lub ponowne
                        przywrócenie ustawień. <br />
                        <br />
                        <strong>Wielkość czcionki </strong> &mdash; z&nbsp;pomocą przycisków <b>+</b> i&nbsp;<b>-</b> możesz
                        ustawić wielkość liter najbardziej odpowiadającą Twoim potrzebom. <br />
                        <br />
                        <strong>Wielkość diagramu </strong> &mdash; z pomocą&nbsp;
                        przycisków <b>+</b> i&nbsp;<b>-</b> możesz dopasować wielkość diagramu do własnych
                        potrzeb. <br />
                        <br />
                        <strong>Diagramy z prawej strony przeglądarki </strong> &mdash; 
                        kiedy jednokrotnie klikniesz w interesującą Cię nazwę bakterii na&nbsp;wykresie, 
                        z&nbsp;jego prawej strony pojawią się informacje o&nbsp;częstości
                        występowania wybranego taksonu w stosunku do odpowiednio: rodzaju, rodziny,
                        rzędu, klasy, typu i&nbsp;wreszcie &nbsp;domeny. Im&nbsp;bardziej szczegółowo chcesz
                        poznać Twój mikrobiom, tym więcej diagramów się pojawi.  <br />
                        <br />
                        <strong>Jak korzystać z przeglądarki?</strong><br />
                        Wykres umożliwia bezpośrednią
                        nawigację między poszczególnymi poziomami klasyfikacji. Dlatego, jeśli
                        chcesz uzyskać przybliżony widok dla wybranej grupy bakterii, kliknij dwa
                        razy w wybrane pole wykresu. Natomiast, aby powrócić do wyższego poziomu
                        klasyfikacji, wybierz jedną z&nbsp;nazw wyświetlanych w centralnej jego części.
                        <br />
                        <br />
                        <strong>Jak znajdziesz interesującą Cię bakterię?</strong><br />
                        Jeśli znasz taksonomię, możesz przechodzić przez kolejne poziomy, wklikując
                        się w konkretne typy, klasy, rzędy, rodziny i&nbsp;rodzaje. Jeśli natomiast
                        interesuje Cię, czy w Twojej próbce zidentyfikowana została dana grupa, 
                        (np.&nbsp;rodzaj, rodzina) lub konkretna bakteria wpisz odpowiednią nazwę np.
                        <i>Clostridium butyrylicum</i> w&nbsp;pole “wyszukaj” w&nbsp;górnej części wykresu. Z&nbsp;boku
                        pola wyświetli się informacja, ile wyników zostało odnalezionych, obszary
                        wykresu pasujące do Twojego wyszukiwania zapytania zostaną wówczas
                        podświetlone, a&nbsp;ich nazwy oznaczone ramką. Aby skasować wprowadzoną nazwę
                        kliknij “x” znajdujący się po&nbsp;prawej stronie pola tekstowego.
                      </p>
                    </div>
                  </div>
                </div>
                <KronaFrame
                  prefix={code}
                  width={`${size?.width || 1200}px`}
                  height={`${size?.height || 1000}px`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
