import { logout } from "App";
import logo from "assets/images/logoNB.png";
import sygnet from "assets/images/sygnetNB.png";
import { useHistory, useLocation } from "react-router-dom";
import { useAuthStore } from "stores/auth";

interface Props {
  children: React.ReactNode;
}

export const ViewContainer = ({ children }: Props) => {
  const isAuthenticated = useAuthStore((store) => store.isAuthenticated);
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <div className="pageWrapper px-3 px-md-4">
      <div className="header">
        <div className="appLogo">
          <a href="https://nanobiome.pl">
            <img src={logo} alt="Nanobiome" />
          </a>
        </div>
        {isAuthenticated && (
          <div className="headerRightSide d-flex align-items-center">
            <div
              className="d-flex align-items-center pe-3 cursor-pointer"
              onClick={() => history.push("/")}
            >
              <img className="sygnetSmall mt-0" src={sygnet} alt="" />
              <h5 className="mb-0">Panel pacjenta</h5>
            </div>
            <button className="btnSecondary px-2" onClick={logout}>
              Wyloguj się
            </button>
          </div>
        )}
      </div>

      {pathname === "/" && (
        <a
          rel="noopener noreferrer"
          href="https://nanobiome.pl/badanie-mikrobioty/"
          target="_blank"
          className="cta"
        >
          <div className="ctaBackground"></div>
          <div className="ctaText">
            Chcesz zakupić <strong>Nanobiome</strong>
            <span className="moreInfo">?</span>
          </div>
        </a>
      )}
      {children}
    </div>
  );
};
